// FloatingWhatsApp.js
import React from 'react';
import styled from 'styled-components';

// Styled component for the floating icon
const FloatingWhatsAppContainer = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  width: 80px;
  height: 80px;
  background-color: #25D366;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  will-change: transform; // Improve performance


  img {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 768px) {
    width: 70px;
    height: 70px;
    right: 10px; // Adjust for better floating behavior
    bottom: 20px;

    img {
      width: 70px;
      height: 70px;
    }
  }

  @supports (position: sticky) {
    margin-left: 90vw;
    position: sticky; // Fallback to sticky for better behavior on some mobile browsers
      @media (max-width: 768px) {
        margin-left: 80vw;
      }
  }
`;

const WhatsAppIcon = styled.img`
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 90%;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

// The actual floating button with a link to WhatsApp
const FloatingWhatsApp = () => {
  return (
    <FloatingWhatsAppContainer>
      <a href="https://api.whatsapp.com/send?phone=918618976974&text=Hello%20Binge%20Town!" target="_blank" rel="noopener noreferrer">
        <WhatsAppIcon
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="WhatsApp"
        />
      </a>
    </FloatingWhatsAppContainer>
  );
};

export default FloatingWhatsApp;
