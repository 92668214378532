import styled from "styled-components";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { ImWhatsapp } from "react-icons/im";
import { IoMdCall } from "react-icons/io";
import { FcFaq } from "react-icons/fc";
import { TbCreditCardRefund, TbReceiptRupee } from "react-icons/tb";

const LinkHeading = styled.h2`
  display: flex;
  justify-content: center;
  line-height: 26px;
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
  font-family: "DM Sans", sans-serif;
`;

const HaveDoubtsContainer = styled.div`  
  padding: 1rem 1rem;
  background-color: #34255c;
  containerType: "inline-size"
  @media only screen and (max-width: 786px) {
    padding: 1rem 0rem;
  }
`;

const StyledLink = styled(Link)`  
  text-decoration: none;
  color: #34255C;
  background-color: #ffffff;
  padding: 0.5rem;  
  display: flex;
  @media only screen and (max-width: 786px) {
    display: flex;
  }
`;

const StyledLink2 = styled(Link)`  
  text-decoration: none;
  color: #34255C;
  background-color: #fdbd18;
  padding: 0.5rem;
  display: flex;
  @media only screen and (max-width: 786px) {
    display: flex;
  }
`;

const LinkContainer = styled.div<{ $wrap?: boolean }>`
  justify-content: start;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-Top: 0.5rem;
  gap: 0.5rem;
  display: flex; 
  font-family: "DM Sans", sans-serif;
  flex-wrap: ${(props) => (props.$wrap ? "wrap" : "nowrap")};
  @media only screen and (max-width: 786px) {
    line-height: 14px;
    padding: 0 1rem;
    flex-wrap: wrap;
  }
`;

const HaveDoubts = (props: any) => {
  const { wrap } = props;
  const isMweb = useMediaQuery("(max-width:786px)");
  const iconSize = isMweb ? 32 : 42;
  return (
    <>
      <HaveDoubtsContainer>
        <LinkHeading>Have doubts?</LinkHeading>
        <LinkContainer $wrap = {wrap}>
          <StyledLink to="/faqs"
            title="Faqs | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
          ><FcFaq size={iconSize}/><span>&nbsp;</span> Our FAQs are super detailed and kill 99.99% of doubts :)
          </StyledLink>
          <StyledLink2 to="https://api.whatsapp.com/send/?phone=918618976974&text&type=phone_number&app_absent=0" target="_blank"
            title="WhatsApp | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
          ><ImWhatsapp size={iconSize}/><span>&nbsp;</span> Click to connect with our Smart WhatsApp ChatBot and Agents.
          </StyledLink2>
          <StyledLink to="/refund_policy"
            title="Refund Policy | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
          ><TbCreditCardRefund size={iconSize}/><span>&nbsp;</span> Worried about change of plans? Our Refund Policy has got you.
          </StyledLink>
          <StyledLink2 to="/pricing"
            title="Pricing | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
          ><TbReceiptRupee size={iconSize}/><span>&nbsp;</span> Confused about pricing? Check out this explainer.
          </StyledLink2>
          <StyledLink to="tel:+918618976974"
            title="Contact Us | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
          ><IoMdCall size={iconSize}/><span>&nbsp;</span> Want to speak with us on call? Just tap here to get connected.
          </StyledLink>
        </LinkContainer>
      </HaveDoubtsContainer>
    </>
  );
};
export default HaveDoubts;
