import styled from "styled-components";

export const LeftViewSectionContainer = styled.div`
  margin: 3rem 4rem;

  @media only screen and (max-width: 786px) {
  width: 90%;
    margin: 5% 5%;
  }
`;

export const Overview = styled.div`
  display: flex;
  margin: 1.5rem 0;
  flex-direction: column;

  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

export const MwebOverview = styled.div`
  display: flex;
  margin: 1.5rem 0;
  flex-direction: column;
  background-color: #edecea;
  border-radius: 16px;
  padding: 1rem 1rem;
  @media only screen and (min-width: 786px) {
    display: none;
  }
`;

export const Detail = styled.div`
  display: flex;
  margin: 1rem 0;
  @media only screen and (max-width: 786px) {
    margin: 0;
  }
`;

export const DetailIcon = styled.div`
  @media only screen and (max-width: 786px) {
  }
`;

export const DetailTxt = styled.div`
  top: 0.2rem;
  position: relative;
  margin-left: 0.5rem;
  margin-right: 2rem;

  @media only screen and (max-width: 786px) {
    font-weight: 400;
    font-size: 14px;
  }
`;

export const OverviewDetails = styled.div`
  display: flex;

  @media only screen and (max-width: 786px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const OverviewHeading = styled.h1`
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  margin: 1rem 0;

  @media only screen and (max-width: 786px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const BackButtonContainer = styled.div`
  flex: 0.5;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const BackButtonTxt = styled.div``;
