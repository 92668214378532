import styled from "styled-components";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TypeAnimation } from "react-type-animation";

import { useNavigate } from "react-router-dom";
import { getMainContainerInfo } from "../../../../../redux/selectors/webConfigSelector";
import { useSelector } from "react-redux";
import "./MainContainer.styled.css";
import SearchTheatresSection from "../SearchTheatresSection/SearchTheatresSection";
import backgroundImage from "../../../../../assets/images/hero section (2).png";

const MainContainerView = styled.div`
  position: relative;
  overflow: hidden;
  height: 600px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  @media only screen and (max-width: 768px) {
    width:100%;
    margin:0%;
`;

const MainContainerHeading = styled.div`
  font-weight: 700;
  font-size: 56px;
  line-height: 68px;
  color: #ffffff;
  width: 50rem;

  position: absolute;
  top: 8rem;
  left: 16rem;

  @media only screen and (max-width: 1400px) {
    top: 6rem;
    left: 12rem;
  }
  @media only screen and (max-width: 1400px) {
    top: 4rem;
    left: 8rem;
  }
  @media only screen and (max-width: 1000px) {
    top: 2rem;
    left: 4rem;
    width: 40rem;
  }
  @media only screen and (max-width: 798px) {
    font-size: 32px;
    line-height: 39px;
    width: 20rem;
    left: 1rem;
    top: 2rem;
  }
`;

const BookNowButton = styled.button`
  background-color: #ffffff;
  color: #000000;
  border-radius: 64px;
  width: 264px;
  height: 48px;
  border: none;

  position: absolute;
  bottom: 8rem;
  left: 16rem;

  font-size: 18px;
  font-weight: 500;
  line-height: 22px;

  @media only screen and (max-width: 1400px) {
    bottom: 6rem;
    left: 12rem;
  }

  @media only screen and (max-width: 1100px) {
    bottom: 5rem;
    left: 10rem;
  }

  @media only screen and (max-width: 1000px) {
    bottom: 2rem;
    left: 4rem;
  }

  @media only screen and (max-width: 798px) {
    left: 1rem;
    bottom: 2rem;
  }
`;

const MainContainer = () => {
  const navigate = useNavigate();
  const isMweb = useMediaQuery("(max-width:786px)");
  const mainContainerInfo = useSelector(getMainContainerInfo);

  const { mobile = {}, desktop = {} } = mainContainerInfo || {};
  const { backgroundImageURL: mwebBackgroundImageURL } = mobile || {};
  const { backgroundImageURL } = desktop || {};

  const handleBookNow = () => {
    navigate("private-theaters-booking");
  };

  return (
    <MainContainerView>
      <img
        className="zoom-in-out-box"
        src={backgroundImage}
        alt="Book_now"
        title="Book_now"
        //loading="lazy"
        style={{
          objectFit: "cover",
          overflow: "hidden",
          width: "100%",
          height: "100%",
        }}
      />
      {/* <h1>
        <MainContainerHeading>
          <TypeAnimation
            sequence={[
              "India's #1 Private Theater Celebration space.",
              1000,
              "Book for birthdays, anniversaries, romantic dates, etc.",
              1000,
              "Cake, food, gifts, photoshoot, fog effect, and much more",
              1000,
            ]}
            wrapper="span"
            speed={50}
            repeat={Infinity}
          />
        </MainContainerHeading>
      </h1> */}
      {/* <BookNowButton onClick={handleBookNow}>Book Now</BookNowButton> */}
      <SearchTheatresSection />
    </MainContainerView>
  );
};
export default MainContainer;
