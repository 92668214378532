// Layout.js
import React from 'react';
import FloatingWhatsApp from './features/Home/screens/floatingWhatsapp'; // Import the WhatsApp component
import { useLocation } from 'react-router-dom';

const Layout = ({ children }:any) => {
    const location = useLocation();

    // List of routes where FloatingWhatsApp should not be displayed
    const hideWhatsAppRoutes = ['/login', '/signup', '/some-other-route'];
  
    // Check if the current path is in the list of routes to hide FloatingWhatsApp
    const shouldHideWhatsApp = location.pathname.includes("booking_overview") || location.pathname.includes("terms_conditions_agreement");
  return (
    <>
      {/* Other common components like header or footer */}
      
      {/* Render the child components (page content) */}
      {children}
      
      {/* Floating WhatsApp Icon */}
      {!shouldHideWhatsApp && <FloatingWhatsApp />}
    </>
  );
};

export default Layout;
