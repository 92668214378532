import Black_forest from "../../../../../../assets/images/Cake/Black_forest.png";
import Butterscotch from "../../../../../../assets/images/Cake/Butterscotch.png";
import Chocolate from "../../../../../../assets/images/Cake/Chocolate.png";
import Pineapple from "../../../../../../assets/images/Cake/Pineapple.png";
import Red_velvet from "../../../../../../assets/images/Cake/Red_velvet.png";
import Blueberry from "../../../../../../assets/images/Cake/Blueberry.png";
import Mango from "../../../../../../assets/images/Cake/Mango.png";
import Heart_red_velvet from "../../../../../../assets/images/Cake/Heart_red_velvet.png";
import Death_by_chocolate from "../../../../../../assets/images/Cake/Death_by_chocolate.png";
import Choco_almond from "../../../../../../assets/images/Cake/Choco_almond.png";
import Heart_pinata from "../../../../../../assets/images/Cake/Heart_pinata.png";

export const CAKE = {
  "Black Forest Cake": {
    id: 1,
    name: "Black Forest",
    imgUrl: Black_forest,
    price: 500,
  },
  "Butterscotch Cake": {
    id: 2,
    name: "Butterscotch",
    imgUrl: Butterscotch,
    price: 500,
  },
  "Chocolate Cake": {
    id: 3,
    name: "Chocolate",
    imgUrl: Chocolate,
    price: 500,
  },
  "Pineapple Cake": {
    id: 4,
    name: "Pineapple",
    imgUrl: Pineapple,
    price: 500,
  },
  "Round Red Velvet Cake": {
    id: 5,
    name: "Red velvet",
    imgUrl: Red_velvet,
    price: 600,
  },
  "Blueberry Cake": {
    id: 6,
    name: "Blueberry",
    imgUrl: Blueberry,
    price: 600,
  },
  "Mango Cake": {
    id: 7,
    name: "Mango",
    imgUrl: Mango,
    price: 600,
  },
  "Heart Red Velvet Cake": {
    id: 8,
    name: "Heart Red Velvet",
    imgUrl: Heart_red_velvet,
    price: 700,
  },
  "Death by chocolate Cake": {
    id: 9,
    name: "Death by chocolate",
    imgUrl: Death_by_chocolate,
    price: 700,
  },
  "Choco Almond Cake": {
    id: 10,
    name: "Choco Almond",
    imgUrl: Choco_almond,
    price: 750,
  },
  "Heart Pinata Cake": {
    id: 11,
    name: "Heart Pinata",
    imgUrl: Heart_pinata,
    price: 850,
  },
};
