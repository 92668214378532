import styled from "styled-components";

import ServiceCard from "./components/ServiceCard/ServiceCard";
import { CgArrowLongRight } from "react-icons/cg";
import Carousel from "react-multi-carousel";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getOurServices } from "../../../../redux/selectors/webConfigSelector";
import "./OurServices.styled.css";

const OurServicesContainer = styled.div`
  display: flex;
  background-color: #edecea;
  padding: 7.5rem 7rem;
  flex-direction: column;

  @media only screen and (max-width: 786px) {
    padding: 2rem 1.5rem;
    display: none;
  }
`;

const MwebOurServicePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #edecea;
  padding: 2rem 1.5rem;
  gap: 2rem;

  @media only screen and (min-width: 786px) {
    display: none;
  }
`;

const Heading = styled.h2`
  font-size: 40px;
  font-weight: 600;
  line-height: 49px;
  padding-bottom: 3rem;
  font-family: "DM Sans", sans-serif;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const MwebServiceHeader = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 24px;
  justify-content: space-between;
  font-family: "DM Sans", sans-serif;
`;

const OurServiceHeader = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 24px;
  justify-content: space-between;
 font-family: "DM Sans", sans-serif;
`;

const MwebHeading = styled.div`
  font-weight: 600;
  font-size: 24px;
  font-family: "DM Sans", sans-serif;
`;

const MwebAllServices = styled.div`
  flex: 3;
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 18px;
  align-items: flex-end;
  color: #34255c;
  text-decoration: underline;
  gap: 0.5rem;
  font-family: "DM Sans", sans-serif;
`;

const AllServices = styled.div`
  flex: 3;
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 18px;
  align-items: flex-end;
  color: #34255c;
  text-decoration: underline;
  gap: 0.5rem;
  font-family: "DM Sans", sans-serif;
`;

const MwebAllServicesTxt = styled.div`
  display: flex;
`;

const AllServicesTxt = styled.div`
  display: flex;
`;

const MwebAllServicesIcon = styled.div`
  display: flex;
`;

const AllServicesIcon = styled.div`
  display: flex;
`;

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1363 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1363, min: 1050 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 1050, min: 300 },
    items: 2,
  },
  smallMobile: {
    breakpoint: { max: 300, min: 0 },
    items: 1,
  },
};

const OurServices = () => {
  const ourServices = useSelector(getOurServices) || [];

  const navigate = useNavigate();
  const handleAllServices = () => {
    navigate("our_services");
  };

  return (
    <>
      <OurServicesContainer>
        <Heading>Our services</Heading>
        <Carousel
          responsive={responsive}
          autoPlay={true}
          swipeable={true}
          draggable={true}
          showDots={true}
          infinite={true}
          partialVisible={false}
        >
          {ourServices &&
            ourServices.length > 0 &&
            ourServices.map((service: any, i: Number) => {
              return (
                <ServiceCard
                  imgUrl={service.serviceImageURL}
                  title={service.service}
                  isBestSeller={service.isBestSeller}
                  ind={service.ind}
                  key={service.ind}
                />
              );
            })}
        </Carousel>
        <AllServices onClick={handleAllServices}>
          <AllServicesTxt>All Services</AllServicesTxt>
          <AllServicesIcon>
            <CgArrowLongRight />
          </AllServicesIcon>
        </AllServices>
      </OurServicesContainer>

      <MwebOurServicePageContainer>
        <MwebServiceHeader>
          <OurServiceHeader>
            <MwebHeading>Our</MwebHeading>
            <MwebHeading>Services</MwebHeading>
          </OurServiceHeader>

          <MwebAllServices onClick={handleAllServices}>
            <MwebAllServicesTxt>All Services</MwebAllServicesTxt>
            <MwebAllServicesIcon>
              <CgArrowLongRight />
            </MwebAllServicesIcon>
          </MwebAllServices>
        </MwebServiceHeader>

        <Carousel
          responsive={responsive}
          autoPlay={true}
          swipeable={true}
          draggable={true}
          showDots={true}
          infinite={true}
          partialVisible={false}
          dotListClass="custom-dot-list-style"
        >
          {ourServices &&
            ourServices.length > 0 &&
            ourServices.map((service: any, i: Number) => {
              return (
                <ServiceCard
                  imgUrl={service.serviceImageURL}
                  title={service.service}
                  isBestSeller={service.isBestSeller}
                  ind={service.ind}
                  key={`mweb_`+service.ind}
                />
              );
            })}
        </Carousel>
      </MwebOurServicePageContainer>
    </>
  );
};

export default OurServices;
