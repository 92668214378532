import SelectDateCity from "./SelectDateCity/SelectDateCity";
import * as S from "./SearchTheatresSection.style";

const SearchTheatresSection = () => {

    return (
        <>
            <S.SearchTheatersContainer id="select-date-city-container">
                <S.TitleContainer><S.SearchHeading>India's #1 Private celebrations venue</S.SearchHeading>
                    <S.SearchSubHeading>Book for birthdays, anniversaries, date night, bride to be and much more!</S.SearchSubHeading></S.TitleContainer>
                <S.SelectDateCityContainer><SelectDateCity /></S.SelectDateCityContainer>
            </S.SearchTheatersContainer >
        </>

    );

}
export default SearchTheatresSection;