import styled from "styled-components";

export const BookingDoneContainer = styled.div`

display: flex;
width: 220px;
padding: 5px;
align-items: center;
gap: 4px;
border-radius: var(--xl, 20px);
background: var(--Primary-Orange-orange-1, #FFF4E6);
margin-top:10px;
width: 100%;
`
export const BookingDoneTextRow = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
`
export const BookingDoneNumber = styled.div`
color: var(--Neutral-Grey-grey-13, #101010);

/* Headings/H5 */
font-family: "DM Sans", sans-serif;
font-size: var(--Font-Size-Heading-Heading-5, 17px);
font-style: normal;
font-weight: 600;
line-height: var(--Line-Height-Heading-2xl, 25px); /* 150% */
`
export const BookingDoneText = styled.div`
color: var(--Neutral-Grey-grey-13, #101010);

/* Paragraph/SM/P-SM-SemiBold */
font-family: "DM Sans", sans-serif;
font-size: var(--Font-Size-Paragraph-p-sm, 14px);
font-style: normal;
font-weight: 600;
line-height: var(--Line-Height-Paragraph-sm, 20px); /* 142.857% */
`
export const PeopleSearchingText = styled.div`
margin-top:5px;
width:100%;
color: var(--Secondary-Blue-blue-6, #1481BA);

/* Paragraph/SM/P-SM-SemiBold */
font-family: "DM Sans", sans-serif;
font-size: var(--Font-Size-Paragraph-p-sm, 14px);
font-style: italic;
font-weight: 600;
line-height: var(--Line-Height-Paragraph-sm, 20px); /* 142.857% */
`