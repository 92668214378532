import ReactGA from "react-ga";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import Calls from "../apis/Calls";

const initializeGA = (trackingID: any) => {
  ReactGA.initialize(trackingID);
};

const trackEvent = (category: any, action: any, label: any) => {
  console.log("### GA event:", category, ":", action, ":", label);
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

const useSessionManagement = (page: string) => {
  const location = useLocation();

  useEffect(() => {
    const fetchSession = async () => {
      try {
        let sessionId = localStorage.getItem("tbt_njkwe_sessionId");
        if (!sessionId) {
          sessionId = generateSessionId();
          localStorage.setItem("tbt_njkwe_sessionId", sessionId);
          const searchParams = new URLSearchParams(location.search);
          const utmSource = searchParams.get("utm_source");
          const utmMedium = searchParams.get("utm_medium");
          const utmCampaign = searchParams.get("utm_campaign");
          const utmTerm = searchParams.get("utm_term");
          const utmContent = searchParams.get("utm_content");
          const browser_print = navigator.userAgent;
          if (
            !utmSource &&
            !utmCampaign &&
            !utmMedium &&
            !utmTerm &&
            !utmContent
          ) {
            return;
          }
          // Store UTM parameters in an object
          const utm_data = {
            id: sessionId,
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaign: utmCampaign,
            utm_term: utmTerm,
            utm_content: utmContent,
            browser_print,
            stage: page,
          };
          localStorage.setItem(
            "tbt_njkwe_sessionData",
            JSON.stringify(utm_data)
          );
          localStorage.setItem("tbt_njkwe_sessionId", sessionId);
          Calls.sendUserData("UtmData", utm_data);
          Cookies.set('tbt_session', JSON.stringify(utm_data), { expires: 7 })
        } else {
          let utm_data = localStorage.getItem("tbt_njkwe_sessionData");
          if (utm_data) {
            let utm_data_json = JSON.parse(utm_data);
            if (utm_data_json.stage == page) {
              console.log("same page re-render", page, utm_data_json);
              return;
            }
            utm_data_json.stage = page;
            if (
              !utm_data_json.utm_source &&
              !utm_data_json.utm_campaign &&
              !utm_data_json.utm_medium &&
              !utm_data_json.utm_term &&
              !utm_data_json.utm_content
            ) {
              console.log("emopty utm");
              return;
            }
            Calls.sendUserData("UtmData", utm_data_json);
            Cookies.set('tbt_session', JSON.stringify(utm_data), { expires: 7 })
          } else {
            console.log("Session Id found but No working");
          }
        }
      } catch (error) {
        console.error("Error fetching session:", error);
      }
    };

    fetchSession();
  }, []);

  // Function to generate a unique session ID
  const generateSessionId = () => {
    return `session_${Math.random().toString(36).substr(2, 20)}`;
  };

  return null; // You can return any data or function you want to expose from this hook
};

export { initializeGA, trackEvent, useSessionManagement };
