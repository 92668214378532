import styled from "styled-components";
import Banner from "../../Home/screens/Banner/Banner";
import CopyRight from "../../Home/screens/CopyRight/CopyRight";
import Footer from "../../Home/screens/Footer/Footer";
import Header from "../../Home/screens/Header/Header";
import { Helmet, HelmetProvider } from "react-helmet-async";
import seoStrings from "../../../seoSrings";
import { MerchantReturnPolicy } from "schema-dts";
import { helmetJsonLdProp } from "react-schemaorg";

const TermsAndConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  // laptop
  @media only screen and (min-width: 710px) {
    padding: 6rem 9rem;
  }

  // tablet and mobile
  @media only screen and (max-width: 1110px) {
    padding: 2.5rem 1.5rem;
  }
`;

const Heading = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 2rem;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const CustomerReviewDesc = styled.div`
  margin: 1rem 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

const LastUpdatedDate = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin: 0.5rem 0 1rem 0;

  @media only screen and (max-width: 768px) {
    margin: 0.5rem 0 0.5rem 0;
  }
`;

const ListHeading = styled.div`
  margin-top: 1rem;
`;

const OlList = styled.ol`
  type: 1;
  line-height: 24px;
  margin: 0;
`;

const List = styled.li`
  margin: 1rem 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

const TermsAndConditions = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet
        script={[
          helmetJsonLdProp<MerchantReturnPolicy>({
            "@context": "https://schema.org",
            "@type": "MerchantReturnPolicy",
            "name": "Binge Town Terms and Conditions",
            "description": "Learn about our website's terms and conditions.",
            "url": "https://thebingetown.com/terms_conditions"
          }),
        ]}
        >
          <title>{seoStrings['t&c']['title']}</title>
          <meta
                name="description"
                content={seoStrings['t&c']['description']}
            />
          <meta
                name="keywords"
                content={seoStrings['t&c']['keyword']}
            />
        </Helmet>
      </HelmetProvider>
      <Header />
      <Banner />

      <TermsAndConditionsContainer>
        <Heading>Terms & Conditions - BCKS Franchise LLP</Heading>
        <LastUpdatedDate>Last updated on Apr 26th 2023</LastUpdatedDate>

        <CustomerReviewDesc>
          The Website Owner, including subsidiaries and affiliates ("Website" or
          "Website Owner" or "we" or "us" or "our") provides the information
          contained on the website or any of the pages comprising the website
          ("website") to visitors ("visitors") (cumulatively referred to as
          "you" or "your" hereinafter) subject to the terms and conditions set
          out in these website terms and conditions, the privacy policy and any
          other relevant terms and conditions, policies and notices which may be
          applicable to a specific section or module of the website.
        </CustomerReviewDesc>
        <CustomerReviewDesc>
          Welcome to our website. If you continue to browse and use this website
          you are agreeing to comply with and be bound by the following terms
          and conditions of use, which together with our privacy policy govern
          BCKS FRANCHISE LLP's relationship with you in relation to this
          website.
        </CustomerReviewDesc>
        <CustomerReviewDesc>
          The term 'BCKS FRANCHISE LLP' or 'us' or 'we' refers to the owner of
          the website whose registered/operational office is 2nd floor, 10/1,
          2nd floor, graphite India road, KR Puram hobli, hoodi, Bangalore
          560048. The term 'you' refers to the user or viewer of our website.
        </CustomerReviewDesc>
        <ListHeading>
          The use of this website is subject to the following terms of use:
        </ListHeading>
        <OlList>
          <List>
            The content of the pages of this website is for your general
            information and use only. It is subject to change without notice
          </List>
          <List>
            Neither we nor any third parties provide any warrently or guarantee
            as to the accuracy, timeliness, performance, completeness or
            suitability of the information and materials found or offered on
            this website for any particular purpose. You acknowledge that such
            information and materials may contain inaccuracies or errors and we
            expressly exclude liability for any such inaccuracies or errors to
            the fullest extent permitted by law.
          </List>
          <List>
            Your use of any information or materials on this website is entirely
            at your own risk, for which we shall not be liable. It shall be your
            own responsibility to ensure that any products, services or
            information available through this website meet your specific
            requirements.
          </List>
          <List>
            This website contains materials which is owned by or licensed to us.
            This material includes, but is not limited to, the design, layout,
            look, apperance and graphics. Reproduction is prohibited other than
            in accordance with the copyright notice, which forms part of these
            terms and conditions.
          </List>
          <List>
            All trademarks reproduced in this website which are not the property
            of, or licensed to, the operator are acknowledged on the website.
          </List>
          <List>
            Unauthorized use of this website may give rise to a claim for
            damages and/or be a criminal offense.
          </List>
          <List>
            From time to time this website may also include links to other
            websites.These links are provided for your convenience to provide
            further information.
          </List>
          <List>
            You may not create a link to this website from another website or
            document without BCKS FRANCHISE LLP as prior written consent.
          </List>
          <List>
            Your use of this website and any dispute arising out of such use of
            the website is subject to the laws of India or other regulatory
            authority.
          </List>
          <List>
            We as a merchant shall be under no liability whatsoever in respect
            of any loss or damage arising directly or indirectly out of the
            decline of authorization for any Transaction, on Account of the
            Cardholder having exceeded the preset limit mutually agreed by us
            with our acquiring bank from time to time.
          </List>
        </OlList>
      </TermsAndConditionsContainer>

      <Footer />
      <CopyRight />
    </>
  );
};
export default TermsAndConditions;
