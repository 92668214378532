import { useEffect } from "react";
import { Link } from "react-router-dom";
import * as S from "./CopyRight.style";

const CopyRight = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.CopyRightContainer>
      <S.CopyRightTxt>
        Copyright &copy;2022 BCKS Franchise LLP - Private Movie Theaters - All
        rights reserved
      </S.CopyRightTxt>
      <S.CopyRightsLinks>
        <Link
          to="/refund_policy"
          title="Refund Policy | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town" 
          style={{ textDecoration: "none", color: "#ffffff", fontWeight: 600 }}
        >
          Refund Policy
        </Link>
        <Link
          to="/terms_conditions"
          title="Terms and Conditions | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
          style={{ textDecoration: "none", color: "#ffffff", fontWeight: 600 }}
        >
          Terms and Conditions
        </Link>
        <Link
          to="/privacy_policy"
          title="Privacy Policy | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
          style={{ textDecoration: "none", color: "#ffffff", fontWeight: 600 }}
        >
          Privacy Policy
        </Link>
      </S.CopyRightsLinks>
    </S.CopyRightContainer>
  );
};
export default CopyRight;
