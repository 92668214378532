import styled from "styled-components";

export const AboutUsPageContainer = styled.div`
  display: flex;
  flex-direction: column;

  // laptop
  @media only screen and (min-width: 710px) {
    padding: 6rem 9rem;
  }

  // tablet and mobile
  @media only screen and (max-width: 1110px) {
    padding: 2rem 1.5rem;
  }

  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

export const MwebAboutUsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;

  @media only screen and (min-width: 786px) {
    display: none;
  }
`;

export const Heading = styled.h1`
  flex: 1;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 3rem;

  @media only screen and (max-width: 1000px) {
    font-size: 24px;
    margin-bottom: 1rem;
  }
`;

export const RightHalf = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const CustomerReviewDesc = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 2rem;

  @media only screen and (max-width: 1000px) {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 1rem;
  }
`;

export const LeftHalf = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 1rem;
    gap: 2rem;
  }
`;
