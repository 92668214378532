import styled from "styled-components";

export const SearchTheatersContainer = styled.div`
    display: flex;
    flex-direction: column;
  height: auto;
  align-items: center;
  justify-content: center;
  position: absolute; /* Added to position it over the image */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1; /* Added to ensure it's above the image */
  margin-top: 20px;
  margin-bottom: 20px;
  height: 100%;
  width: 95%;
  margin-right: 2.5%;
  margin-left: 2.5%;
`
export const SearchHeading = styled.h1`
width: 100%;
text-align: center;
    color: white;
font-size: var(--Font-Size-Heading-Heading-3, 30px);
line-height: 35px;
font-style: normal;
font-weight: 600;
margin-bottom: 10px;
`
export const SearchSubHeading = styled.h2`
text-align: center;
   align-self: stretch;
   color: white;
font-size: var(--Font-Size-Paragraph-p-md-base, 20px);
font-style: normal;
font-weight: 400;
`
export const TitleContainer = styled.div`

    display: flex;
    align-items: start;
    flex-direction: column;
`
export const SelectDateCityContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

`