import LightGallery from "lightgallery/react";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import "./Gallery.css";

export function Gallery({ images }) {
  return (
    <div className="App">
      <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]}>
        {images &&
          images.length > 0 &&
          images.map((image, ind) => {
            return (
              <a href={image} key={ind} title={`galleryImg_${ind}`}>
                <img className="galleryImg" alt="img" title="img" src={image} />
              </a>
            );
          })}
      </LightGallery>
    </div>
  );
}
