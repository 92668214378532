import { serviceAvailableAtCitiesSelector,locationCodeMapSelector } from "../../../../../../redux/selectors/webConfigSelector";
import { useSelector,useDispatch,shallowEqual } from "react-redux";
import { useEffect } from "react";
import { fetchWebConfig } from "../../../../../../apis/ConfigAPI";
import { setWebConfig } from "../../../../../../redux/actions/webConfigActions";
import {PROD, VERSION} from "../../../../../../endPointsConfig";
import {
  getSelectedCity,
  getSelectedLocation
} from "../../../../../../redux/selectors/bookingSelector";
import {
    setTheaterInfo,
    setSelectedTheaterDate,
    setSelectedCity,
    setSelectedLocation,
    setLocationsCityWise
  } from "../../../../../../redux/actions/bookingActions";
import RightViewSection from "../RightViewSection/RightViewSection";
import { store } from "../../../../../../store";
import { City } from "../../../../../Home/screens/HeroSectionWidget/SearchTheatresSection/SelectDateCity/SelectDateCity";
const CityCardPage = () =>{
    const locationCodeMap = useSelector(locationCodeMapSelector) || {}
    const dispatch = useDispatch();
    const selectedCity = useSelector(getSelectedCity) || "";
    const selectedLocation = useSelector(getSelectedLocation, shallowEqual) || {};
    const citiesData = useSelector(serviceAvailableAtCitiesSelector);
    useEffect(() => {
        if (locationCodeMap && Object.keys(locationCodeMap).length === 0) {
          fetchWebConfig()
            .then((response) => {
              dispatch(setWebConfig(response));
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }, [locationCodeMap, dispatch]);
      const extractCityNameFromPath = (pathParts: string[]): string=> {
        if (pathParts.length > 1) {
            // Extract the city name by splitting the second part
            const cityPart = pathParts[1];
            const cityName = cityPart.split('-').slice(-1)[0]; // Get the last part after splitting by '-'
            return cityName.charAt(0).toUpperCase() + cityName.slice(1); // Capitalize the first letter
        }
        return ""; // Return null if city name cannot be determined
    };
    const fetchLocationInfo = (locationCode: any) => {
      const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
      fetch(`${PROD}/webConfigv2?location=${locationCode}&newWeb=${VERSION}`,
            {
              method: "GET",
              headers: {
                  "sessionId": sessionId  // Fallback value ensures this is always a string
              }
          }
          )
          .then((response) => {
              return response.json();
          })
          .then((response) => {
              dispatch(setTheaterInfo(response));
          })
          .catch((error: any) => {
              console.log(error);
          });
    }
    useEffect(() => {
        // Get the current URL
        const url = window.location.href;

        // Create a URL object
        const parsedUrl = new URL(url);
    
        // Extract the city and location from the path
        const pathParts = parsedUrl.pathname.split('/');
        const city = extractCityNameFromPath(pathParts);
        let location;// Adjust index based on your actual URL structure
        let fullLocObj;
        const cityData = citiesData.find((cityObj:City) => cityObj.cityName === city);
        if (cityData &&  cityData.locations?.length > 0) {
          // Set to the first location if no location is provided in the URL
          location = cityData.locations[0].locationName;
          fullLocObj = cityData.locations[0];
      }
        
        
        const today = new Date();
        const selectedDate = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate() + 1);
          
            // Dispatch actions to update state
            dispatch(setSelectedTheaterDate({
                year: selectedDate.getFullYear(),
                month: selectedDate.getMonth(),
                day: selectedDate.getDate(),
                fullDate: selectedDate,
            }));
            store.dispatch(setSelectedCity(null, city));
            dispatch(setSelectedLocation(fullLocObj));
            store.dispatch(setLocationsCityWise());
            fetchLocationInfo(locationCodeMap[location]);
            
    }, []);

    return(
        <>
        <RightViewSection/></>
    );
}
export default CityCardPage;