import webConfigReducer from "./redux/reducers/webConfigReducer";
import bookingReducer from "./redux/reducers/bookingReducer";

import { createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import blogsReducer from "./redux/reducers/blogsReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root", // Key to identify storage in localStorage
  storage, // Use localStorage as default storage
  whitelist: ["webConfig", "blogs","booking"], // Reducers to persist (optional)
  //blacklist: ['booking']
};


const rootReducer = combineReducers({
  webConfig: webConfigReducer,
  booking: bookingReducer,
  blogs: blogsReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const initialState = {};
const middleware = [thunk];
export const store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);
