import styled from "styled-components";
import Header from "../../Home/screens/Header/Header";
import Banner from "../../Home/screens/Banner/Banner";
import ImgFirst from "./components/ImgFirst";
import TextFirst from "./components/TextFirst";
import { SERVICES } from "./components/OurServices";
import CopyRight from "../../Home/screens/CopyRight/CopyRight";
import Footer from "../../Home/screens/Footer/Footer";
import { useSelector } from "react-redux";
import { getOurServicesImagesUrl } from "../../../redux/selectors/webConfigSelector";
import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import ButtonView from "../../../components/common/ButtonView";
import { useMediaQuery } from "@mui/material";

const OurServicePageContainer = styled.div`
  display: flex;
  flex-direction: column;

  // laptop
  @media only screen and (min-width: 710px) {
    padding: 6rem 9rem;
  }

  // tablet and mobile
  @media only screen and (max-width: 1110px) {
    padding: 2rem 1.5rem;
  }

  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

const MwebOurServicePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;

  @media only screen and (min-width: 1000px) {
    display: none;
  }
`;

const Heading = styled.h1`
  flex: 1;
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 2rem;
  font-family: "DM Sans", sans-serif;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
  }
`;

const ButtonContainer = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
`;

const OurServicePage = () => {
  const serviceImageUrl = useSelector(getOurServicesImagesUrl) || [];
  const { state } = useLocation();
  const { ind } = state || {};
  const ref = useRef(new Array(SERVICES.length).fill(null));
  const isMweb = useMediaQuery("(max-width:786px)");
  useEffect(() => {
    if (!isMweb) {
      const el = document.getElementById(ind);
      window.scrollTo({ behavior: "smooth", top: el?.offsetTop ? el?.offsetTop - 150 : el?.offsetTop });
    }
    if (isMweb) {
      const mwebel = document.getElementById('mweb' + ind?.toString());
      // window.scrollTo({ behavior: "smooth", top: mwebel?.offsetTop ? mwebel?.offsetTop - 20 : mwebel?.offsetTop});
      mwebel?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
    }
  }, []);

  return (
    <>
    <title>The Binge Town | Private Celebration Services</title>
      <Header />
      <Banner />

      <OurServicePageContainer>
        <Heading>Our Services</Heading>
        {SERVICES &&
          SERVICES.length > 0 &&
          SERVICES.map((service, ind) => {
            return (
              <React.Fragment key={`service_${ind}`} >
                {ind % 2 === 0 ? (
                  <TextFirst
                    title={service.title}
                    desc={service.desc}
                    imgUrl={serviceImageUrl[ind] || service.imgUrl}
                    isBestSeller={service.isBestSeller}
                    ind={ind.toString()}
                  />
                ) : (
                  <ImgFirst
                    title={service.title}
                    desc={service.desc}
                    imgUrl={serviceImageUrl[ind] || service.imgUrl}
                    isBestSeller={service.isBestSeller}
                    ind={ind.toString()}
                  />
                )}
              </React.Fragment>
            );
          })}
      </OurServicePageContainer>

      <MwebOurServicePageContainer>
        <Heading>Our Services</Heading>
        {SERVICES &&
          SERVICES.length > 0 &&
          SERVICES.map((service, ind) => {
            return (
              <React.Fragment key={`service_${ind}`}>
                <ImgFirst
                  title={service.title}
                  desc={service.desc}
                  imgUrl={serviceImageUrl[ind] || service.imgUrl}
                  isBestSeller={service.isBestSeller}
                  ind={'mweb' + ind.toString()}
                />
              </React.Fragment>
            );
          })}
      </MwebOurServicePageContainer>
      <ButtonContainer>
        <Link
          to="/"
          title="City Page | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
          style={{ textDecoration: "none", color: "#ffffff" }}
        >
          <ButtonView text={"Book Now"} />
        </Link>
      </ButtonContainer>
      <Footer />
      <CopyRight />
    </>
  );
};

export default OurServicePage;
