import "./ServiceCard.css";
import styled from "styled-components";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

type propsTypes = {
  imgUrl: any;
  isBestSeller?: boolean;
  title: string;
  ind: string;
};

const ServiceCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 16.5rem;
  height: 16.5rem;
  margin: 0;
  justify-content: center;
  border-radius: 12px;

  @media only screen and (max-width: 786px) {
    width: 14.5rem;
    height: 14.5rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  height: 12rem;
`;

const TxtContainer = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  background-color: #ffffff;
  color: #000000;
  padding: 1rem;
  font-family: "DM Sans", sans-serif;

  @media only screen and (min-width: 786px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const BestSellerTag = styled.div`
  background-color: #ef1d4a;
  color: #ffffff;
  border-radius: 24px;
  width: 90px;
  font-weight: 600;
  padding: 0.3rem;
  position: absolute;
  right: 9rem;
  top: -0.5rem;
  font-family: "DM Sans", sans-serif;
  @media only screen and (min-width: 786px) {
    right: 6rem;
  }
`;

const ServiceCard = (props: propsTypes) => {
  const { imgUrl, title, isBestSeller, ind } = props;
  const navigate = useNavigate();
  const handleAllServices = (ind:any) => {
    navigate(`our_services`, { state: { ind } });
  };

  return (
    <>
      {isBestSeller ? <BestSellerTag>Best-seller</BestSellerTag> : null}
      <ServiceCardContainer onClick={() => handleAllServices(ind)}>
        <ImageContainer>
          <img
            src={imgUrl}
            alt={title}
            title={title}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              overflow: "hidden",
              borderRadius: "16px 16px 0 0",
            }}
          />
        </ImageContainer>

        <TxtContainer style={{ borderRadius: "0 0 16px 16px" }}>
          {title}
        </TxtContainer>
      </ServiceCardContainer>
    </>
  );
};
export default ServiceCard;
