import styled from "styled-components";

export const AgreementContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 3rem;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

export const Actions = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-top: 1rem;
  display: flex;
`;

export const TermsConditionsContainer = styled.div`
  flex: 1;
  @media only screen and (max-width: 1000px) {
    margin-top: 2rem;
  }
`;

export const ButtonViewContainer = styled.div`
  padding: 2rem 0;
  display: flex;
  flex: 1;
`;

export const ButtonView = styled.button`
  flex: 1;
  background-color: ${(props) => (props.disabled ? "#E5E5E5" : "#34265c")};
  color: ${(props) => (props.disabled ? "#000000" : "#ffffff")};
  border: none;
  border-radius: 64px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  padding: 0.6rem;
  font-family: "Montserrat";
`;

export const TermsConditionsTitle = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
`;

export const TermsConditions = styled.ol`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const TermsCondition = styled.li``;

export const RefundPolicyHeading = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  padding-top: 1rem;
`;

export const RefundPolicyTxt = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  padding-top: 1rem;
`;

export const Checkbox = styled.input`
  accent-color: #34265c;
  border-color: #34265c;
  border: 5px solid #34265c;
  width: 1.5rem;
  height: 1.5rem;
`;

export const AgreeBox = styled.div`
  margin-left: 1rem;
  top: 0.2rem;
  position: relative;
`;

export const BackButtonContainer = styled.div`
  flex: 0.5;
  display: flex;
  // justify-content: center;
  align-items: center;
  gap: 0.5rem;
  // padding: 1.5rem 0;
`;

export const BackButtonTxt = styled.div``;

export const ExtraSpace = styled.div`
  flex: 0.5;
`;

export const FooterContainer = styled.div`
  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem 0rem;
  flex: 1;
`;

export const ModalHeading = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  justify-content: space-between;
`;

export const ModalHeading1 = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  justify-content: center;
`;

export const ModalHeadingTxt = styled.div``;

export const ModalDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

export const ModalSubTxt = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
`;
