import styled from "styled-components";

export const FooterContainer = styled.div`
  background-color: #ebe5d4;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const LogoContainer = styled.div`
  flex: 0.5;
  @media only screen and (max-width: 786px) {
    margin: 1rem 0;
    width: 6rem;
    height: 4.5rem;
  }
`;

export const CompanyServicesContainer = styled.div`
  flex: 0.5;
  @media only screen and (max-width: 700px) {
    margin: 1rem 0;
  }
`;

export const ReachUsContainer = styled.div`
  flex: 1;
  @media only screen and (max-width: 700px) {
    margin: 1rem 0;
  }
`;

export const SocialMediaContainer = styled.div`
  flex: 0.5;
  @media only screen and (max-width: 700px) {
    margin: 1rem 0;
  }
`;

export const Heading = styled.div`
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 2rem;
  font-family: "DM Sans", sans-serif;

  @media only screen and (max-width: 786px) {
    padding-bottom: 1.5rem;
  }
`;

export const SocialMediaLogo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media only screen and (max-width: 786px) {
    justify-content: flex-start;
    gap: 2rem;
  }
`;

export const ReachUsDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.3rem 0;
  flex-wrap: wrap;
`;

export const ReachUsText = styled.div`
  padding-left: 1rem;
  flex-wrap: wrap;
`;

export const AddressLine = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: "DM Sans", sans-serif;
`;

export const ServicesContainer = styled.div`
  // display: flex;
  // flex: column;

  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

export const MWebServicesContainer = styled.div`
  display: flex;
  flex: column;

  gap: 3rem;
  @media only screen and (min-width: 786px) {
    display: none;
  }
`;

export const ColumnContainer = styled.div`
  // display: flex;
  // flex: column;
`;

export const Services = styled.div`
  padding: 0.3rem 0;
  font-family: "DM Sans", sans-serif;
  @media only screen and (max-width: 786px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
`;
export const ReachUsIcon = styled.div``;
