import Header from "../../../../../Home/screens/Header/Header";
import { HiOutlineExclamationCircle } from "react-icons/hi2";

import CopyRight from "../../../../../Home/screens/CopyRight/CopyRight";
import { HiArrowLeft } from "react-icons/hi";
import { OCCASIONS } from "./OCCASIONS";
import BookingSummary from "../BookingSummery/BookingSummary";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCouponInfo,
  getIsCouponAppliedSuccessfully,
  getPersonalDetails,
  getSelectedOccasion,
  getSelectedTheater,
  getSubTotalAmount,
} from "../../../../../../redux/selectors/bookingSelector";
import {
  setIsOccasionPageValid,
  setPersonalDetails,
  setSelectedOccasion,
} from "../../../../../../redux/actions/bookingActions";
import { useEffect, useState } from "react";
import * as S from "./Occasion.style";
import { getUpdateBookingDetail } from "../../../../../../redux/selectors/blogsSelector";

const Occasion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedTheater = useSelector(getSelectedTheater);
  const selectedOccasion = useSelector(getSelectedOccasion);
  const personalDetails = useSelector(getPersonalDetails) || {};

  const updateBookingDetail = useSelector(getUpdateBookingDetail) || {};
  const [activeOccasion, setActiveOccasion] = useState(
    selectedOccasion?.name || updateBookingDetail?.decorType
  );
  const DECORATION = selectedTheater?.decoration || [];

  const [formData, setFormData] = useState({
    personName:
      personalDetails?.personName ||
      updateBookingDetail?.names?.split(",")[0] ||
      "",
    partnerName:
      personalDetails?.partnerName ||
      updateBookingDetail?.names?.split(",")[1] ||
      "",
  });

  const isCouponApplied = useSelector(getIsCouponAppliedSuccessfully);
  const subTotalAmount = useSelector(getSubTotalAmount) || 0;
  const coupon = useSelector(getCouponInfo);
  const { code: couponCode, price: couponPrice } = coupon || {};

  const [isFormValid, setIsFormValid] = useState<Boolean>(false);
  const [showBookingSummary, setShowBookingSummary] = useState<Boolean>(false);
  const [nickNameTxt, setNickNameTxt] = useState("");

  const [personNameError, setPersonNameError] = useState("");
  const [partnerNameError, setPartnerNameError] = useState("");

  const handleNext = () => {
    navigate("choose_cake");
  };

  const handleSelectOccasion = (occasion: { name: string; price: number }) => {
    dispatch(setSelectedOccasion(occasion));
    setActiveOccasion(occasion.name);

    if (
      [
        "Birthday",
        "Baby Shower",
        "Congratulations",
        "Farewell",
        "Bride to be",
      ].includes(occasion?.name)
    ) {
      setFormData((prevState) => ({
        ...prevState,
        partnerName: "",
      }));
    }
  };

  const { name: occasionName } = selectedOccasion;

  // function handleFormSubmit(e: any) {
  //   e.preventDefault();

  //   const formElement = e.target;
  //   const isValid = formElement.checkValidity();

  //   setIsFormValid(isValid);

  //   if (isValid) {
  //     dispatch(setPersonalDetails(formData));
  //   }
  //   if (isValid && activeOccasion) {
  //     dispatch(setIsOccasionPageValid(true));
  //   }
  // }

  useEffect(() => {
    if (["Birthday"].includes(occasionName)) {
      setNickNameTxt("Nick name of birthday person");
    } else if (["Baby Shower"].includes(occasionName)) {
      setNickNameTxt("Nick name of Mom to be");
    } else if (["Bride to be"].includes(occasionName)) {
      setNickNameTxt("Nick name of bride to be");
    } else if (["Farewell"].includes(occasionName)) {
      setNickNameTxt("Nick name of Farewell person");
    } else if (["Congratulations"].includes(occasionName)) {
      setNickNameTxt("Nick name");
    } else if (["Marriage Proposal", "Romantic Date", "Anniversary"].includes(occasionName)) {
      setNickNameTxt("Your nick name");
    }
    if (
      ["Marriage Proposal", "Romantic Date", "Anniversary"].includes(
        occasionName
      )
    ) {
      if (
        formData.personName.length > 0 &&
        formData.personName.length <= 7 &&
        formData.partnerName.length > 0 &&
        formData.partnerName.length <= 7
      ) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    } else {
      if (formData.personName.length > 0 && formData.personName.length <= 7) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }
    if (formData.personName.length > 7) {
      setPersonNameError("Please enter maximum 7 characters for nick name");
    }
    if (formData.partnerName.length > 7) {
      setPartnerNameError(
        "Please enter maximum 7 characters for partner's nick name"
      );
    }
  }, [activeOccasion, formData]);

  useEffect(() => {
    if (!isFormValid) return;
    if (isFormValid) {
      dispatch(setPersonalDetails(formData));
    }
  }, [formData, isFormValid]);

  useEffect(() => {
    if (isFormValid && (activeOccasion || selectedOccasion?.name)) {
      dispatch(setIsOccasionPageValid(true));
    } else {
      dispatch(setIsOccasionPageValid(false));
    }
  }, [isFormValid, activeOccasion, selectedOccasion]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setPersonNameError("");
    setPartnerNameError("");
  };

  const handleShowBookingSummary = () => {
    setShowBookingSummary(!showBookingSummary);
  };

  return (
    <>
    <title>The Binge Town | Choose Occasion</title>
      <Header />
      <S.BookingOverviewContainer>
        <S.LeftViewSectionContainer>
          <S.BackButtonContainer onClick={() => navigate(-1)}>
            <HiArrowLeft size={24} />
            <S.BackButtonTxt>Back</S.BackButtonTxt>
          </S.BackButtonContainer>
          <S.Overview>
            <S.HeadingContainer>
              <S.HeadingTxt>Select the Occasion</S.HeadingTxt>
            </S.HeadingContainer>
            <S.OccasionsView>
              {DECORATION &&
                DECORATION.length > 0 &&
                DECORATION.map((occasion: { name: string; price: number }) => {
                  return (
                    <S.OccasionView
                      key={occasion.name}
                      onClick={() => handleSelectOccasion(occasion)}
                    >
                      {activeOccasion === occasion.name ||
                      selectedOccasion.name === occasion.name ? (
                        <img
                          src={
                            OCCASIONS[occasion.name as keyof typeof OCCASIONS]
                              .selectedImgUrl
                          }
                          alt={occasion.name}
                          title={occasion.name}
                          style={{
                            width: "165px",
                            height: "148px",
                          }}
                        />
                      ) : (
                        <img
                          src={
                            OCCASIONS[occasion.name as keyof typeof OCCASIONS]
                              .imgUrl
                          }
                          alt={occasion.name}
                          title={occasion.name}
                          style={{
                            width: "165px",
                            height: "148px",
                          }}
                        />
                      )}
                      <S.OccasionTitle>{occasion.name}</S.OccasionTitle>
                    </S.OccasionView>
                  );
                })}
            </S.OccasionsView>
          </S.Overview>

          <S.PersonalDetails>
            {/* <form onSubmit={handleFormSubmit}> */}
            <S.InputContainer>
              <label htmlFor="personName">{nickNameTxt} &#x2a;</label>
              <S.InputBox
                placeholder="Type here"
                name="personName"
                required
                maxLength={7}
                value={formData?.personName}
                onChange={handleChange}
              />
              {personNameError ? (
                <S.CouponErrorTxtContainer>
                  <HiOutlineExclamationCircle size={18} />
                  <S.CouponErrorTxt>{personNameError}</S.CouponErrorTxt>
                </S.CouponErrorTxtContainer>
              ) : null}
            </S.InputContainer>
            <S.InputContainer>
              {["Marriage Proposal", "Romantic Date", "Anniversary"].includes(
                occasionName
              ) ? (
                <>
                  <label htmlFor="partnerName">
                    Your partner's nick name &#x2a;
                  </label>
                  <S.InputBox
                    placeholder="Type here"
                    name="partnerName"
                    maxLength={7}
                    value={formData?.partnerName}
                    onChange={handleChange}
                  />
                  {partnerNameError ? (
                    <S.CouponErrorTxtContainer>
                      <HiOutlineExclamationCircle size={18} />
                      <S.CouponErrorTxt>{partnerNameError}</S.CouponErrorTxt>
                    </S.CouponErrorTxtContainer>
                  ) : null}
                </>
              ) : null}
            </S.InputContainer>
            {/* </form> */}
          </S.PersonalDetails>

          <S.MwebFooterContainer>
            <S.PriceBreakupSummaryContainer>
              <S.PriceBreakupHeader>
                <S.PriceBreakupTotal>Total</S.PriceBreakupTotal>
                <S.PriceBreakupPrice>
                  &#8377;{" "}
                  {isCouponApplied
                    ? subTotalAmount - couponPrice
                    : subTotalAmount}
                </S.PriceBreakupPrice>
              </S.PriceBreakupHeader>
              <S.PriceBreakup>
                <S.BookingSummaryHeading onClick={handleShowBookingSummary}>
                  <S.BookingSummaryTxt> Booking summary</S.BookingSummaryTxt>
                  {showBookingSummary ? (
                    <MdOutlineKeyboardArrowUp size={24} />
                  ) : (
                    <MdOutlineKeyboardArrowDown size={24} />
                  )}
                </S.BookingSummaryHeading>
              </S.PriceBreakup>
              <S.PriceBreakupDetails>
                {showBookingSummary ? <BookingSummary /> : null}
              </S.PriceBreakupDetails>
            </S.PriceBreakupSummaryContainer>
            <S.BookNowBtn
              onClick={handleNext}
              type="submit"
              disabled={!isFormValid}
            >
              Next step
            </S.BookNowBtn>
          </S.MwebFooterContainer>
        </S.LeftViewSectionContainer>
        <S.RightViewSectionContainer>
          <BookingSummary fromPage="occasion" />
        </S.RightViewSectionContainer>
      </S.BookingOverviewContainer>

      <CopyRight />
    </>
  );
};

export default Occasion;
