const seoStrings = {
  "Jubilee Hills": {
    "h1": "",
    "title": "Bday Celebration Party Halls in Jubilee Hills, Hyderabad - The Binge Town ",
    "description": "Get the best bday decoration in Hyderabad at The Binge Town. Book for all kinds of occasions and make it special. Perfect venue for celebration and surprises",
    "keyword": "bday decoration, private movie theater, bday party place, celebration venue in Hyderabad",
  },
  "Suchitra Circle": {
    "h1": "",
    "title": "Bday Celebration Party Halls in Suchitra Circle, Hyderabad - The Binge Town ",
    "description": "Get the best bday decoration in Hyderabad at The Binge Town. Book for all kinds of occasions and make it special. Perfect venue for celebration and surprises",
    "keyword": "bday decoration, private movie theater, bday party place, celebration venue in Hyderabad",
  },
  "Karol Bagh": {
    "h1": "",
    "title": "Bday Celebration Party Halls in Karol Bagh, Delhi - The Binge Town ",
    "description": "Get the best bday decoration in Delhi at The Binge Town. Book for all kinds of occasions and make it special. Perfect venue for celebration and surprises",
    "keyword": "bday decoration, private movie theater, bday party place, celebration venue in Delhi",
  },
  "Rajaji Nagar": {
    "h1": "",
    "title": "Private Movie Theater in Rajaji Nagar, Bangalore - The Binge Town",
    "description": "Get the best balloon decoration for birthday in Bangalore at The Binge Town. Completely private place for celebrations",
    "keyword": "balloon decoration for bday, celebration space in bengaluru, private cinema hall, place for celebration, bday surprise in bangalore",
  },
  "Banashankari": {
    "h1": "",
    "title": "Private Movie Theater in Banashankari, Bangalore - The Binge Town",
    "description": "Best birthday celebration places in Bengaluru. Private movie theaters with decoration and great service",
    "keyword": "celebration places in bengaluru, private party place, birthday party venue, bday surprise place, bday celebration ideas",
  },
  "Koramangala": {
    "h1": "",
    "title": "Private Movie Theater in Kormangala, Bangalore - The Binge Town",
    "description": "Get the best bday decoration in Bangalore at The Binge Town. Book for all kinds of occasions and make it specials",
    "keyword": "bday decoration, private movie theater, bday party place, celebration venue in Bangalore",
  },
  "Nagavara": {
    "h1": "",
    "title": "Private Movie Theater in Nagavara, Bangalore - The Binge Town",
    "description": "Get the best bday decoration in Bangalore at The Binge Town. Book for all kinds of occasions and make it specials",
    "keyword": "bday decoration, private movie theater, bday party place, celebration venue in Bangalore",
  },
  "Whitefield": {
    "h1": "",
    "title": "Private Movie Theater in Whitefield, Bangalore - The Binge Town",
    "description": "Best place to celebrate bday in Bangalore. Personal theaters for surprises - The Binge Town",
    "keyword": "best place for celebration, decorated private theaters, personal theater, romantic decoration",
  },
  "Himayatnagar": {
    "h1": "",
    "title": "Bday Celebration Party Halls in Himayat Nagar, Hyderabad - The Binge Town ",
    "description": "Get the best bday decoration in Hyderabad at The Binge Town. Book for all kinds of occasions and make it special. Perfect venue for celebration and surprises",
    "keyword": "bday decoration, private movie theater, bday party place, celebration venue in Hyderabad",
  },
  "Miyapur": {
    "h1": "",
    "title": "Bday Celebration Party Halls in Miyapur, Hyderabad - The Binge Town ",
    "description": "Get the best bday decoration in Hyderabad at The Binge Town. Book for all kinds of occasions and make it special. Perfect venue for celebration and surprises",
    "keyword": "bday decoration, private movie theater, bday party place, celebration venue in Hyderabad",
  },
  "Janakpuri": {
    "h1": "",
    "title": "Bday Celebration Party Halls in Janakpuri, Delhi - The Binge Town ",
    "description": "Get the best bday decoration in Delhi at The Binge Town. Book for all kinds of occasions and make it special. Perfect venue for celebration and surprises",
    "keyword": "bday decoration, private movie theater, bday party place, celebration venue in Delhi",
  },
  "Laxmi Nagar": {
    "h1": "",
    "title": "Bday Celebration Party Halls in Laxmi Nagar, Delhi - The Binge Town ",
    "description": "Get the best bday decoration in Delhi at The Binge Town. Book for all kinds of occasions and make it special. Perfect venue for celebration and surprises",
    "keyword": "bday decoration, private movie theater, bday party place, celebration venue in Delhi",
  },
  "Saket": {
    "h1": "",
    "title": "Bday Celebration Party Halls in Saket, Delhi - The Binge Town ",
    "description": "Get the best bday decoration in Delhi at The Binge Town. Book for all kinds of occasions and make it special. Perfect venue for celebration and surprises",
    "keyword": "bday decoration, private movie theater, bday party place, celebration venue in Delhi",
  },
  "delhi": {
    "h1": "",
    "title": "The Binge Town | Celebration space in Delhi",
    "description": "The top places for birthday party in Delhi- The Binge Town. Best balloon decoration for all kinds of occasions. Perfect venue for Celebrations and Surprises",
    "keyword": "Celebration Place in Delhi, birthday party, balloon decoration, private theater, bday surprise",
  }, 
   "hyderabad": {
    "h1": "",
    "title": "The Binge Town | Celebration space in Hyderabad",
    "description": "The top places for birthday party in Hyderabad- The Binge Town. Best balloon decoration for all kinds of occasions. Venue for celebrations and surprises",
    "keyword": "Celebration Place in Hyderabad birthday party, balloon decoration, private theater, bday surprise",
  },
  "bangalore": {
    "h1": "",
    "title": "The Binge Town | Celebration space in Bengaluru",
    "description": "The top places for birthday party in Bangalore- The Binge Town. Best balloon decoration for all kinds of occasions. Plan all kinds of celebrations and surprices",
    "keyword": "Celebration Place in Bangalore, birthday party, balloon decoration, private theater, bday surprise",
  },
  "home": {
    "h1": "",
    "title": "The Binge Town | Private Theaters for Celebration Surprises",
    "keyword": "Private movie theater, place for celebration, Private movie screening, birthday celebration, private theater in bangalore, party venue, celebration venue, private theater in Hyderabad, private theater in Delhi",
    "description": "The Binge Town is India's #1 chain of Private Theaters. It is the best place for all kinds of celebrations and suprises like Birthdays, Anniversaries, etc"
  },
  "home2": {
    "h1": "",
    "title": "Private Theaters for Celebrations and surprises - The Binge Town ",
    "keyword": "Private movie theater, place for celebration, Private movie screening, birthday celebration, private theater in bangalore, party venue, celebration venue, private theater in Hyderabad, private theater in Delhi",
    "description": "The Binge Town is India's #1 chain of Private Theaters. It is the best place for all kinds of celebrations and suprises like Birthdays, Anniversaries, etc"
  },
  "aboutUs": {
    "h1": "",
    "title": "About Us | Balloon Decoration for Birthday in Bangalore & Hyderabad - The Binge Town ",
    "description": "Private Movie theaters in Hyderabad and Bangalore for celebrations and surprises",
    "keyword": "Private Theaters, Private movie theater, private cinema hall, celebration venue, birthday celebration",
  },
  "gallery": {
    "h1": "Gallery",
    "title": "Gallery | Celebration Venue in Bangalore, Hyderabad & Delhi - The Binge Town",
    "description": "Top celebration venues in Bangalore and Hyderabad for birthdays, anniversaries, date night, bachelorette, etc,",
    "keyword": "balloon decoration for bday, celebration space in Hyderabad private cinema hall, place for celebration, bday surprise in bangalore",
  },
  "refundPolicy": {
    "h1": "Refund Policy",
    "title": "The Binge Town | Refund Policy",
    "description": "",
    "keyword": "Refund Policy, Customer Service",
  },
  "contactUs": {
    "h1": "Contact Us",
    "title": "Contact Us | Anniversary Celebration in Bangalore, Hyderabad & Delhi - The Binge Town",
    "description": "Top quality Private places for surprises and celebrations in Bangalore, Hyderabad and Delhi",
    "keyword": "best place for celebration, decorated private theaters, personal theater, romantic decoration"
  },
  "faq": {
    "h1": "Faq",
    "title": "The Binge Town | Private Theaters | FAQs",
    "description": "Content Policy, Refund Policy, Rules and regulations inside theater",
    "keyword": "best place for celebration, decorated private theaters, personal theater, romantic decoration"
  },
  "t&c": {
    "h1": "Terms & Conditions",
    "title": "The Binge Town | Terms & Conditions",
    "description": "Content Policy, Refund Policy, Rules and regulations inside theater",
    "keyword": "Terms & Conditions"
  },
  "blogs": {
    "h1": "Blogs",
    "title": "Blogs | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town",
    "description": "beautiful balloon decoration for birthday or anniversary or any other occasion in Bangalore, Hyderabad & Delhi",
    "keyword": "best private theater, decorated private theater, balloon decoration, bday celebration places, birthday celebration ideas"
  },
  "pageNotFound": {
    "h1": "Page Not Found",
    "title": "Page Not Found | Anniversary Celebration in Bangalore, Hyderabad & Delhi - The Binge Town",
    "description": "Top quality Private places for surprises and celebrations in Bangalore, Hyderabad and Delhi",
    "keyword": "best place for celebration, decorated private theaters, personal theater, romantic decoration"
  },
}

module.exports = seoStrings;
