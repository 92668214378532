import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAdavanceAmount,
  getBookingSummaryCakes,
  getBookingSummaryAddons,
  getBookingSummaryTheaterCost,
  getExtraNumberOfPeople,
  getIsCouponAppliedSuccessfully,
  getIsOccasionPageValid,
  getSelectedTheater,
  getSubTotalAmount,
  getCouponInfo,
  getFogCount,
} from "../../../../../../redux/selectors/bookingSelector";
import * as S from "./BookingSummary.style";
import { getUpdateBookingDetail } from "../../../../../../redux/selectors/blogsSelector";

const BookingSummary = ({ fromPage = "bookingDetails" }) => {
  const navigate = useNavigate();

  const addOns = useSelector(getBookingSummaryAddons);
  const cake = useSelector(getBookingSummaryCakes);
  //const fogCount = useSelector(getFogCount);
  const theaterCost = useSelector(getBookingSummaryTheaterCost);
  const extraNumberOfPeople = useSelector(getExtraNumberOfPeople);
  const advanceAmount = useSelector(getAdavanceAmount) || 0;
  const subTotalAmount = useSelector(getSubTotalAmount) || 0;
  const isCouponApplied = useSelector(getIsCouponAppliedSuccessfully);
  const coupon = useSelector(getCouponInfo);
  const updateBookingDetail = useSelector(getUpdateBookingDetail) || {};
  const selectedTheater = useSelector(getSelectedTheater);
  const bookingSummaryTheaterCost = useSelector(getBookingSummaryTheaterCost);
  const { maxPerson, avgPerson } = selectedTheater;
  const { code: couponCode, price: couponPrice = 0 } = coupon || {};
  const isUpdateBooking = Object.keys(updateBookingDetail)?.length > 0;

  const [isDisable, setIsDisable] = useState(
    fromPage === "occasion" ? true : false
  );

  const isOccasionPageValid = useSelector(getIsOccasionPageValid);

  const handleNext = () => {
    if (fromPage === "occasion" && isOccasionPageValid) {
      navigate("choose_cake");
    } else if (fromPage === "cake") {
      navigate("choose_decoration");
    } else if (fromPage === "decoration") {
      if (isUpdateBooking)
        navigate(`/terms_conditions_agreement/${updateBookingDetail?.id}`);
      else navigate(`/terms_conditions_agreement`);
    }
  };

  useEffect(() => {
    if (fromPage === "occasion" && isOccasionPageValid) {
      setIsDisable(false);
    } else if (fromPage === "occasion") {
      setIsDisable(true);
    }
  }, [isOccasionPageValid, fromPage]);

  const peopleNumbers =
    bookingSummaryTheaterCost?.numberOfPeople ||
    avgPerson + extraNumberOfPeople;

  return (
    <S.BookingSummaryContainer>
      <S.Heading>Booking summary</S.Heading>
      <S.PriceBreakup>
        <S.DetailPriceBreakup>
          <S.Section>
            <S.SectionTxt>
              {theaterCost?.name} ({peopleNumbers} ppl)
            </S.SectionTxt>
            <S.SectionPrice>&#8377; {theaterCost?.price}</S.SectionPrice>
          </S.Section>
          {theaterCost?.isDecorCostAdded ? (
            <S.Section>
              <S.SectionTxt>Decoration</S.SectionTxt>
              <S.SectionPrice>&#8377; {theaterCost?.decorCost}</S.SectionPrice>
            </S.Section>
          ) : null}

          {cake &&
            cake.map((cake: { name: String; price: number }) => {
              return (
                <S.Section>
                  <S.SectionTxt>{cake?.name} </S.SectionTxt>
                  <S.SectionCount> X 1</S.SectionCount>
                  <S.SectionPrice>&#8377; {cake.price}</S.SectionPrice>
                </S.Section>
              );
            })}

          {addOns &&
            addOns.map((addon: { name: String; price: number }) => {
              //if(addon.name.includes("Fog Entry")){
              //   return (
              //     <S.Section>
              //       <S.SectionTxt>{addon.name} </S.SectionTxt>
              //       <S.SectionCount> X {fogCount}</S.SectionCount>
              //       <S.SectionPrice>&#8377; {addon.price * fogCount}</S.SectionPrice>
              //     </S.Section>
              //   );
              // }
              return (
                <S.Section>
                  <S.SectionTxt>{addon.name} </S.SectionTxt>
                  <S.SectionCount> X 1</S.SectionCount>
                  <S.SectionPrice>&#8377; {addon.price}</S.SectionPrice>
                </S.Section>
              );
            })}
        </S.DetailPriceBreakup>
        <div style={{ borderBottom: "1px solid #C8C8C8" }}></div>

        <S.SubtotalContainer>
          <S.Section>
            <S.SectionTxt>Subtotal</S.SectionTxt>
            <S.SectionPrice>&#8377; {subTotalAmount}</S.SectionPrice>
          </S.Section>
        </S.SubtotalContainer>
        <div style={{ borderBottom: "1px solid #C8C8C8" }}></div>

        {isCouponApplied ? (
          <S.Section>
            <S.SectionTxt>{couponCode}</S.SectionTxt>
            <S.SectionPrice>- &#8377; {couponPrice}</S.SectionPrice>
          </S.Section>
        ) : null}

        <S.AdvancePaymentContainer>
          <S.Section>
            <S.AmountPayableTxt>Advance amount payable</S.AmountPayableTxt>
            <S.SectionPrice>&#8377; {advanceAmount}</S.SectionPrice>
          </S.Section>
        </S.AdvancePaymentContainer>
        <div style={{ borderBottom: "1px solid #C8C8C8" }}></div>

        <S.BalanceAmountContainer>
          <S.Section>
            <S.BalanceAmount>
              <S.SectionTxt>Balance amount</S.SectionTxt>
              <S.SectionSubtxt>(Payable at the venue)</S.SectionSubtxt>
            </S.BalanceAmount>

            <S.SectionPrice>
              &#8377;{" "}
              {isCouponApplied
                ? subTotalAmount - advanceAmount - couponPrice
                : subTotalAmount - advanceAmount}
            </S.SectionPrice>
          </S.Section>
        </S.BalanceAmountContainer>
      </S.PriceBreakup>

      <S.FooterView>
        {fromPage === "bookingDetails" ? (
          ""
        ) : (
          <>
            {fromPage === "decoration" &&
            Object.keys(updateBookingDetail).length === 0 ? (
              <S.ButtonView
                type="button"
                onClick={handleNext}
                disabled={isDisable}
              >
                Pay &#8377; {advanceAmount}
              </S.ButtonView>
            ) : (
              <S.ButtonView
                type="button"
                onClick={handleNext}
                disabled={isDisable}
              >
                Next step
              </S.ButtonView>
            )}
          </>
        )}
      </S.FooterView>
    </S.BookingSummaryContainer>
  );
};

export default BookingSummary;
