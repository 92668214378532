import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
// Define the keyframes animation to move the text
const moveText = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const BannerContainer = styled.div`
  background: linear-gradient(36deg, #1A2A6C 8.48%, #B21F1F 55.8%, #FDBB2D 98.44%);
  color: white;
  text-align: center;
  padding: 0.7rem;
  position: relative;
  overflow: hidden; /* Prevents overflow of the text outside the banner */

  @media only screen and (max-width: 798px) {
    padding: 0.5rem;
  }
`;
const MovingText = styled.div`
  display: inline-block;
  white-space: nowrap; /* Prevents text from breaking into multiple lines */
  animation: ${moveText} 10s linear infinite; /* Adjust the time as per your need */
`;

const Banner = () => {
  let date = new Date();
  let day = date.getDay();

  return (
    <BannerContainer>
      <MovingText>
        {day >= 0 && day <= 4 ? (
          <>
            <b>LIMITED TIME OFFER:</b> Use Coupon <b>"MORNING"</b> to get <b>Rs 300</b> off for morning slots
          </>
        ) : (
          <>
            Can't find your preferred slot? 
            <span>&nbsp;</span>
            <Link
              to="/join_waitlist"
              title="Join Waitlist | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
              style={{ textDecoration: "underline", color: "white" }}
            >
              <b>Join our waitlist</b>
            </Link>
            <span>&nbsp;</span> and we'll reach out to you
          </>
        )}
      </MovingText>
    </BannerContainer>
  );
};
export default Banner;
