import * as React from "react";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import Banner from "../Home/screens/Banner/Banner";
import CopyRight from "../Home/screens/CopyRight/CopyRight";
import Footer from "../Home/screens/Footer/Footer";
import Header from "../Home/screens/Header/Header";
import Input from "@mui/joy/Input";

import Signup from "../../assets/images/Login/Signup.svg";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { useDispatch } from "react-redux";
import { setLoginDetails } from "../../redux/actions/blogsActions";
import { PROD } from "../../endPointsConfig";
import * as S from "./Login.style";

const BookNowBtn = styled.button<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  background-color: ${(props) => (props.disabled ? "#E5E5E5" : "#34265c")};
  color: ${(props) => (props.disabled ? "#000000" : "#ffffff")};
  height: 3rem;
  border: none;
  border-radius: 64px;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  font-family: Montserrat;
`;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [country, setCountry] = React.useState("india");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [error, setError] = React.useState(false);
  const [valid, setValid] = React.useState(false);
  // const [phoneNumberError, setPhoneNumberError] = React.useState("");
  const [loading, setLoading] = React.useState(false); // State to track loading

  const handleOTP = () => {
    setLoading(true);
    const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
    fetch(`${PROD}/sendOtp?phone=${phoneNumber}`,
      {
        headers: {
            "sessionId": sessionId  // Fallback value ensures this is always a string
        }
    }
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.success) {
          dispatch(setLoginDetails(phoneNumber));
          navigate(`otp_verification/${response?.id}`);
        }
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false); // Reset loading when request completes
      });
  };

  React.useEffect(() => {
    var phoneno = /^\d{10}$/;
    if (phoneNumber.match(phoneno)) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [phoneNumber]);

  const handleInputChange = (event: any) => {
    const onlyDigits = event.target.value.replace(/\D/g, "");
    setPhoneNumber(onlyDigits);
    setError(false);

    // if (event.target.value.length > 10 || event.target.value.length < 10) {
    //   setPhoneNumberError("Please enter valid phone number");
    // }
  };

  return (
    <>
    <title> The Binge Town | Login</title>
      <Header />
      <Banner />
      <S.WaitListFormContainer>
        <S.RightHalf>
          <S.Heading>Sign Up/Log In</S.Heading>
          <S.FormContainer>
            <form action="">
              <S.InputContainer>
                <S.LabelBox htmlFor="whatsappNumber">
                  Mobile Number &#x2a;
                </S.LabelBox>
                <Input
                  variant="outlined"
                  placeholder="XXXXXXXXXX"
                  size="lg"
                  required
                  value={phoneNumber}
                  type="tel"
                  onChange={handleInputChange}
                  // maxLength="10"
                  startDecorator={
                    <>
                      <React.Fragment>
                        <Select
                          variant="soft"
                          value={country}
                          onChange={(_, value) => setCountry(value!)}
                          slotProps={{
                            listbox: {
                              variant: "outlined",
                            },
                          }}
                          sx={{
                            mr: 1.5,
                            "&:hover": { bgcolor: "transparent" },
                          }}
                        >
                          <Option value="india">+91</Option>
                          <Option value="usa">+1</Option>
                        </Select>
                      </React.Fragment>
                    </>
                  }
                  style={{
                    margin: "0.7rem 0",
                    borderRadius: "12px",
                    padding: "0.8rem",
                  }}
                />
              </S.InputContainer>
            </form>
          </S.FormContainer>
          {error ? (
            <S.ErrorContainer style={{ color: "red" }}>
              User doesn't exist
            </S.ErrorContainer>
          ) : null}
          <BookNowBtn onClick={handleOTP} disabled={!valid}>
            {loading ? "Sending OTP..." : "Get OTP"}
          </BookNowBtn>
        </S.RightHalf>
        <S.LeftHalf>
          <img src={Signup} alt="login" title="Manage My Bookings | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town" />
        </S.LeftHalf>
      </S.WaitListFormContainer>
      <Footer />
      <CopyRight />
    </>
  );
};
export default Login;
