import styled from "styled-components";
import Banner from "../../Home/screens/Banner/Banner";
import CopyRight from "../../Home/screens/CopyRight/CopyRight";
import Footer from "../../Home/screens/Footer/Footer";
import Header from "../../Home/screens/Header/Header";

const PrivacyPolicyContainer = styled.div`
  display: flex;
  flex-direction: column;

  // laptop
  @media only screen and (min-width: 710px) {
    padding: 6rem 9rem;
  }

  // tablet and mobile
  @media only screen and (max-width: 1110px) {
    padding: 2.5rem 1.5rem;
  }
`;

const Heading = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 2rem;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 0rem;
  }
`;

const CustomerReviewDesc = styled.div`
  margin: 1rem 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @media only screen and (max-width: 786px) {
    margin: 1rem 0 0 0;
  }
`;

const ListHeading = styled.div`
  margin-top: 1rem;
  line-height: 24px;
`;

const UlList = styled.ul`
  line-height: 24px;
  margin: 0;

  @media only screen and (max-width: 786px) {
    margin: 0.5rem;
  }
`;

const List = styled.li`
  margin: 1rem 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @media only screen and (max-width: 786px) {
    margin: 0;
  }
`;

const InfoContainer = styled.div`
  margin: 1rem 0 0 0;
`;

const InfoHeading = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;

  @media only screen and (max-width: 786px) {
    font-weight: 600;
  }
`;

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <Banner />

      <PrivacyPolicyContainer>
        <Heading>Privacy Policy - The Binge Town</Heading>

        <CustomerReviewDesc>
          At The Binge Town, accessible from thebingetown.com, one of our main
          priorities is the privacy of our visitors. This Privacy Policy
          document contains types of information that is collected and recorded
          by The Binge Town and how we use it.
        </CustomerReviewDesc>
        <CustomerReviewDesc>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us at 8618976974.
        </CustomerReviewDesc>
        <CustomerReviewDesc>
          This Privacy Policy applies only to our online activites and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in The Binge Town. This policy is not applicable
          to any information collected offline or via channels other than this
          website.
        </CustomerReviewDesc>

        <InfoContainer>
          <InfoHeading>Consent</InfoHeading>
          <CustomerReviewDesc>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </CustomerReviewDesc>
        </InfoContainer>

        <InfoContainer>
          <InfoHeading>Information we collect</InfoHeading>
          <CustomerReviewDesc>
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </CustomerReviewDesc>
          <CustomerReviewDesc>
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </CustomerReviewDesc>
          <CustomerReviewDesc>
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </CustomerReviewDesc>
        </InfoContainer>

        <InfoContainer>
          <InfoHeading>How we use your information</InfoHeading>
          <ListHeading>
            We use the information we collect in various ways, including to:
          </ListHeading>
          <UlList>
            <List>Provide, operate, and maintain our website</List>
            <List>Improve, personalize, and expand our website</List>
            <List>Understand and analyze how you use our website</List>
            <List>
              Develop new products, services, features, and functionality
            </List>
            <List>
              Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, and for
              marketing and promotional purposes
            </List>
            <List>Send you emails</List>
            <List>Find and prevent fraud</List>
          </UlList>
        </InfoContainer>

        <InfoContainer>
          <InfoHeading>Log Files</InfoHeading>
          <CustomerReviewDesc>
            The Binge Town follows a standard procedure of using log files.
            These files log visitors when they visit websites. All hosting
            companies do this and a part of hosting services' analytics. The
            information collected by log files includen internet protocol (IP)
            addresses, browser type, Internet Service Provider (ISP), date and
            time stamp, referring/exit pages, and possibly the number of clicks.
            These are not linked to any information that is personally
            identifiable. The purpose of the information is for analyzing
            trends, administering the site, tracking users' movement on the
            website, and gathering demographic information.
          </CustomerReviewDesc>
        </InfoContainer>

        <InfoContainer>
          <InfoHeading>Google DoubleClick DART Cookie</InfoHeading>
          <CustomerReviewDesc>
            Google is one of the a third-party vendor on our site. It also uses
            cookies, known as DART cookies, to serve ads to our site visitors
            based upon their visit to www.website.com and other sites on the
            internet. However, visitors may choose to decline the use of DART
            cookies by visiting the Google ad and content network Privacy Policy
            at the following URL - https://policies.google.com/technologies/ads
          </CustomerReviewDesc>
        </InfoContainer>

        <InfoContainer>
          <InfoHeading>Our Advertising Partners</InfoHeading>
          <ListHeading>
            Some of advertisers on our site may use cookies and web beacons. Our
            advertising partners are listed below. Each of our advertising
            partners has their own Privacy Policy for their policies on user
            data. For easier access, we hyperlinked to their Privacy Policies
            below.
          </ListHeading>
          <UlList>
            <List>Google</List>
            <List>Meta</List>
          </UlList>
        </InfoContainer>

        <InfoContainer>
          <InfoHeading>Advertising Partners Privacy Policies</InfoHeading>
          <CustomerReviewDesc>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of The Binge Town.
          </CustomerReviewDesc>
          <CustomerReviewDesc>
            Third-party ad servers or ad networks uses technologies like
            cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that appear on The Binge Town,
            which are sent directly to users' browser. They automatically
            receive your IP address when this occurs. These technologies are
            used to measure the effectiveness of their advertising campaigns
            and/or to personalize the advertising content that you see on
            websites that you visit.
          </CustomerReviewDesc>
          <CustomerReviewDesc>
            Note that The Binge Town has no access to or control over these
            cookies that are used by third-party advertisers.
          </CustomerReviewDesc>
        </InfoContainer>

        <InfoContainer>
          <InfoHeading>Third Party Privacy Policies</InfoHeading>
          <CustomerReviewDesc>
            The Binge Town's Privacy Policy does not apply to other advertisers
            or websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out ot certain options.
          </CustomerReviewDesc>
          <CustomerReviewDesc>
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites.
          </CustomerReviewDesc>
        </InfoContainer>

        <InfoContainer>
          <InfoHeading>CCPA Privacy Rights</InfoHeading>
          <ListHeading>
            Under the CCPA, amoung other rights, California consumers have the
            right to:
          </ListHeading>
          <UlList>
            <List>
              Request that a business that collects a consumer's personal data
              disclose the categories and specific pieces of personal data that
              a business has collected about consumers.
            </List>
            <List>
              Request that a business delete any personal data about the
              consumer that a business has collected.
            </List>
            <List>
              Request that a business that sells a consumer's personal data, not
              sell the consumer's personal data.
            </List>
          </UlList>
          <ListHeading>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any these rights, please contact us.
          </ListHeading>
        </InfoContainer>

        <InfoContainer>
          <InfoHeading>GDPR Data Protection Rights</InfoHeading>
          <ListHeading>
            We would like to make sure you are fully aware of all your data
            protection rights. Every user is entitled to the following:
          </ListHeading>
          <UlList>
            <List>
              The right to access - You have the right to request copies of your
              personal data. We may charge you a small fee for this service.
            </List>
            <List>
              The right to rectification - You have the right to request that we
              correct any information you believe is inaccurate. You also have
              the right to request that we complete the information you believe
              is incomplete.
            </List>
            <List>
              The right to erasure - You have the right to request that we erase
              your personal data, under certain conditions.
            </List>
            <List>
              The right to restrict processing - You have the right to request
              that we restrict the processing of your personal data, under
              certain conditions.
            </List>
            <List>
              The right to object to processing - You have the right to object
              to our processing of your personal data, under certain conditions.
            </List>
            <List>
              The right to data portability - You have the right to request that
              we transfer the data we have collected to another organization, or
              directly to you, under certain conditions.
            </List>
          </UlList>
          <ListHeading>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any these rights, please contact us.
          </ListHeading>
        </InfoContainer>

        <InfoContainer>
          <InfoHeading>Children's Information</InfoHeading>
          <CustomerReviewDesc>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </CustomerReviewDesc>
          <CustomerReviewDesc>
            The Binge Town does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </CustomerReviewDesc>
        </InfoContainer>

        <InfoContainer>
          <InfoHeading>Changes to This Privacy Policy</InfoHeading>
          <CustomerReviewDesc>
            We may update our Privacy Policy from time to time. Thus, we advise
            you to review this page periodically for any changes. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            These changes are effective immediately, after they are posted on
            this page.
          </CustomerReviewDesc>
        </InfoContainer>

        <InfoContainer>
          <InfoHeading>Contact Us</InfoHeading>
          <CustomerReviewDesc>
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to contact us at 8618976974
          </CustomerReviewDesc>
        </InfoContainer>
      </PrivacyPolicyContainer>

      <Footer />
      <CopyRight />
    </>
  );
};
export default PrivacyPolicy;
