export const setWebConfig = (webConfigData) => {
  return {
    type: "SET_WEBCONFIG",
    payload: webConfigData,
  };
};

export const setCitiesInfo = (citiesInfo) => {
  return {
    type: "SET_CITIESINFO",
    payload: citiesInfo,
  };
};
