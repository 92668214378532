import styled from "styled-components";
export const BookingSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: #edecea;

  @media only screen and (max-width: 786px) {
    background-color: #ffffff;
    padding: 0.5rem;
    border-radius: 0 0 24px 24px;
  }
`;

export const Heading = styled.h3`
  flex: 1;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  margin-top: 3rem;

  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

export const PriceBreakup = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media only screen and (max-width: 786px) {
    top: -2rem;
    position: relative;
  }
`;

export const DetailPriceBreakup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
`;

export const SubtotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const AdvancePaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const BalanceAmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const BalanceAmount = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const SectionTxt = styled.div`
  flex: 2;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
`;

export const AmountPayableTxt = styled.div`
  flex: 1;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  @media only screen and (max-width: 786px) {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const AmountPayablePrice = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`;

export const SectionSubtxt = styled.div`
  flex: 1;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
`;

export const SectionCount = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const SectionPrice = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

export const FooterView = styled.div`
  display: flex;
  flex: 1;
  margin-top: 8rem;
  justify-content: flex-end;
  align-items: flex-end;

  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

export const ButtonView = styled.button`
  flex: 1;
  background-color: ${(props) => (props.disabled ? "#E5E5E5" : "#34265c")};
  color: ${(props) => (props.disabled ? "#000000" : "#ffffff")};
  border-radius: 64px;
  border: ${(props) => (props.disabled ? "2px solid #000000" : "")} !important;

  font-weight: 500;
  font-size: 18px;
  text-align: center;
  padding: 0.6rem;
  border: none;
  font-family: "Montserrat";
`;
