const initialState = {
  homePage: {
    mainContainer: {},
    serviceAvailableAtCities: {},
    whyChooseUsImages: [],
    ourServices: [],
    customerReview: [],
    GalleryImages: [],
  },
  ourServicePage: {},
  aboutUsPage: {},
  contactUsPage: {},
  refundPolicyPage: {},
  locations: [],
  cities: [],
};

const webConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_WEBCONFIG":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_CITIESINFO":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default webConfigReducer;
