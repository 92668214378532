import * as S from "./BookingDone.style";
import {ReactComponent as StarsIcon} from "../../../../../../assets/images/Theaters/StarsIcon.svg";
import { getBookingsCount ,getActiveUsersCount} from "../../../../../../redux/selectors/webConfigSelector";
import { useSelector } from "react-redux";
const BookingDone = () =>{
    const bookingsCount = useSelector(getBookingsCount);
    const activeUsersCount = useSelector(getActiveUsersCount);
    return(
        <>
        <S.BookingDoneContainer>
            <StarsIcon/>
            <S.BookingDoneTextRow>
                <S.BookingDoneNumber>{bookingsCount ? bookingsCount : "68000+"}</S.BookingDoneNumber>
                <S.BookingDoneText>bookings completed</S.BookingDoneText>
            </S.BookingDoneTextRow>
        </S.BookingDoneContainer>
         <S.PeopleSearchingText>{activeUsersCount} People searching right now!</S.PeopleSearchingText> 
        </>

    );

};
export default BookingDone;