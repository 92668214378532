import styled from "styled-components";

export const Heading = styled.h2`
color: var(--Neutral-Grey-grey-13, #101010);
text-align: center;

/* Headings/H3 */
font-family: "DM Sans", sans-serif;
font-size: var(--Font-Size-Heading-Heading-3, 36px);
font-style: normal;
font-weight: 600;
line-height: var(--Line-Height-Heading-4xl, 52px); /* 142.857% */
align-self: stretch;
margin-bottom: 20px;
@media only screen and (max-width: 768px) {
    font-size: var(--Font-Size-Heading-Heading-3, 28px);
    line-height: var(--Line-Height-Heading-4xl, 40px);
  }
`
export const SubHeading = styled.h3`
color: var(--Neutral-Grey-grey-10, #343434);
text-align: center;

/* Paragraph/SM/P-SM */
font-family: "DM Sans", sans-serif;
font-size: var(--Font-Size-Paragraph-p-sm, 16px);
font-style: normal;
font-weight: 400;
line-height: var(--Line-Height-Paragraph-sm, 24px); /* 142.857% */
align-self: stretch;
@media only screen and (max-width: 768px) {
    font-size: var(--Font-Size-Paragraph-p-sm, 14px);
    line-height: var(--Line-Height-Paragraph-sm, 20px); /* 142.857% */
  }
`
export const TitleContainer = styled.div`
    display: flex;
flex-direction: column;
align-items: center;
margin-bottom:80px;
padding: 0px 400px;
@media only screen and (max-width: 1025px) {
    margin-bottom: 48px;
    padding: 0px 200px;
  }

@media only screen and (max-width: 768px) {
    margin-bottom: 48px;
    padding: 0px var(--page-margin, 20px);
  }


`
export const QuestionAnswerContent = styled.div`

    display: flex;
padding: 0px 296px;
flex-direction: column;
align-items: center;
gap: 32px;
align-self: stretch;
@media only screen and (max-width: 1025px) {
    gap:24px;
    padding: 0px 150px;

  }

@media only screen and (max-width: 768px) {
    gap:24px;
    padding: 0px var(--page-margin, 20px);
    align-items: flex-start;

  }

`
export const QuestionAnswerContainer = styled.div`

    display: flex;
align-items: flex-start;
gap: 24px;
align-self: stretch;
`
export const Question = styled.div`
color: var(--Neutral-Grey-grey-13, #101010);

/* Paragraph/LG/P-LG */
font-family: "DM Sans", sans-serif;
font-size: var(--Font-Size-Paragraph-p-lg, 18px);
font-style: normal;
font-weight: 400;
line-height: var(--Line-Height-Paragraph-lg, 28px); /* 155.556% */
align-self: stretch;
width: calc(100% - 48px);
margin-bottom: 8px;
@media only screen and (max-width: 768px) {
    font-size: var(--Font-Size-Paragraph-p-lg, 16px);
    line-height: var(--Line-Height-Paragraph-lg, 24px);
  }

`
export const Answer = styled.div`
    align-self: stretch;
    color: var(--Neutral-Grey-grey-10, #343434);

/* Paragraph/MD/P-MD */
font-family: "DM Sans", sans-serif;
font-size: var(--Font-Size-Paragraph-p-md-base, 16px);
font-style: normal;
font-weight: 400;
line-height: var(--Line-Height-Paragraph-md-base, 24px); /* 150% */
width: calc(100% - 48px);
@media only screen and (max-width: 768px) {
    font-size: var(--Font-Size-Paragraph-p-sm, 14px);
    line-height: var(--Line-Height-Paragraph-sm, 20px); /* 142.857% */
  }



`
export const ExpandCollapseIcon = styled.div`
    display: flex;
    justify-content: flex-end;
`
export const GreyLine = styled.div`
background: var(--Neutral-Grey-grey-5, #DBDBDB);
height:0.5px;
width: 100%;
`
export const QuestionAndAnswer = styled.div`
    width: calc(100% - 48px);
`
export const ViewAllButton = styled.div`
    display: flex;
padding: 12px 16px;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: var(--md-base, 12px);
background: var(--Primary-Violet-violet-6, #34265C);
margin-bottom: 80px;
cursor:pointer;
@media only screen and (max-width: 768px) {
    align-self: stretch;
    margin-bottom: 48px;
  }

`
export const ViewAllText = styled.div`
color: var(--Neutral-Grey-grey-1, #FFF);
text-align: center;

/* Paragraph/XS/P-XS-Medium */
font-family: "DM Sans", sans-serif;
font-size: var(--Font-Size-Paragraph-p-xs, 12px);
font-style: normal;
font-weight: 500;
line-height: var(--Line-Height-Paragraph-xs, 16px); /* 133.333% */
`