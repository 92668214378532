import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

// Modal styles
const customStyles: ReactModal.Styles = {
  overlay:{
    zIndex: 1300,
  },
  content: {
    top: '30vh',
    width: '80vw', // Width of the modal
    height: '40vh', // Height of the modal
    zIndex: 9999,
    backgroundColor: 'transparent', // Making background transparent
    border: 'none',
    padding: 0,
    overflow: 'hidden',
    position: 'fixed' as 'fixed',
  },
};

// Bind modal to your app element
Modal.setAppElement('#root');

const VideoPopup: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Check if today is within the last 7 days of the month
  const isLastWeekOfMonth = () => {
    const today = new Date();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastDate = lastDayOfMonth.getDate();
    return today.getDate() > lastDate - 7;
  };

  useEffect(() => {
    const isFirstVisit = localStorage.getItem('videoSeen');
    if (!isFirstVisit && isLastWeekOfMonth()) {
      setModalIsOpen(true);
    }
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
    localStorage.setItem('videoSeen', 'true'); // Mark the video as seen
  };

  return (
    <div>
      {modalIsOpen && ( // Only render the modal if it's open
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Video Modal"
        >
          <ReactPlayer
            url="/popupVideo.mp4" // Replace with your video URL
            playing={modalIsOpen} // Auto-play when modal is open
            muted // Ensure the video is muted for autoplay to work
            width="100%"
            height="100%"
            //onEnded={closeModal} // Close modal when the video ends
            style={{
              objectFit: 'cover', // Ensures the video covers the modal size
              border: 'none',
            }}
          />
          <button
            onClick={closeModal}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              fontSize: '1.5rem',
              color: '#fff',
              cursor: 'pointer',
              zIndex: 1000,
            }}
          >
            ✖
          </button>
        </Modal>
      )}
    </div>
  );
};

export default VideoPopup;
