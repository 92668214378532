import { useNavigate } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import * as S from "./AboutUs.style";
import React from "react";
import { getWhyChooseUsImages } from "../../../../redux/selectors/webConfigSelector";
import { useSelector } from "react-redux";

const AboutUs = () => {
  const navigate = useNavigate();
  const whyChooseUsImagesUrl = useSelector(getWhyChooseUsImages) || [];
  var items = [
    {
      text: "The Binge Town Private Theatres for Celebrations",
      decription:
        "Searching for the perfect celebration venue in Bangalore, Hyderabad or Delhi? Look no further! The Binge Town offers an unparalleled experience that transforms your special moments into unforgettable memories.",
      imageUrl: 'https://storage.googleapis.com/tbt-pics/images/newWeb/whychooseus/whychooseus_1.jpg',
    },
    {
      text: "Birthday Bliss, Anniversary Extravaganza and much more!",
      decription:
        "The best place to celebrate birthdays and anniversaries with a cinematic twist. Our private theatres in Hyderabad, Bangalore and Delhi provide the ideal setting for a unique and personalized celebration. From themed balloon decorations to surprise elements, we've got your special day covered!",
      imageUrl: 'https://storage.googleapis.com/tbt-pics/images/newWeb/whychooseus/whychooseus_2.jpg',
    },
    {
      text: "Surprises Galore – Balloon Decoration Extravaganza",
      decription:
        "Add a pop of joy to your celebrations with our exclusive balloon decorations. Our skilled team ensures that your private theatre is adorned with vibrant balloons, creating a festive and visually stunning atmosphere. It's the perfect backdrop for capturing those Instagram-worthy moments!",
      imageUrl: 'https://storage.googleapis.com/tbt-pics/images/newWeb/whychooseus/whychooseus_3.jpg',
    },
    {
      text: "Private theater with best service guarantee",
      decription:
        "At The Binge Town, customer satisfaction is our top priority. We take pride in being the celebration venue with the best service. Join the ranks of our delighted customers who have made us the highest rated private theater celebration space and rest assured about exceptional celebration experiences. Your happiness is our success.",
      imageUrl: 'https://storage.googleapis.com/tbt-pics/images/newWeb/whychooseus/whychooseus_4.jpg',
    },
    {
      text: "Affordable Private celebration Packages",
      decription:
        "Celebrate without breaking the bank! The Binge Town offers affordable packages that cater to various budgets, ensuring that everyone can enjoy a premium private celebration without compromising on quality.",
      imageUrl: 'https://storage.googleapis.com/tbt-pics/images/newWeb/whychooseus/whychooseus_5.jpg',
    },
  ];

  const handleBookNow = () => {
    //navigate("private-theaters-booking");
    const element = document.getElementById("select-date-city-container");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Scroll smoothly to the section
    }
  };

  return (
    <>
      <S.AboutUsContainer>
        <S.LeftHalf>
          <Carousel
            interval={4000}
            indicatorContainerProps={{
              style: {
                paddingBottom: "2rem",
                width: "100%",
                display: "none",
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                backgroundColor: "#34255C",
                width: "5rem",
                borderRadius: "8px",
                color: "#34255C",
              },
            }}
          >
            {items.map(
              (
                item: {
                  imageUrl: string;
                  text: string;
                  decription: string;
                },
                ind: Number
              ) => {
                return (
                  <React.Fragment key={`about_us_${ind}`}>
                    <S.Heading>Why choose us?</S.Heading>
                    <S.SubText>{item?.text}</S.SubText>
                    <S.Description>{item?.decription}</S.Description>
                    <S.BookNowBtnContainer>
                      <S.BookNowBtn onClick={handleBookNow}>
                        Book Now
                      </S.BookNowBtn>
                    </S.BookNowBtnContainer>
                  </React.Fragment>
                );
              }
            )}
          </Carousel>
        </S.LeftHalf>

        <S.RightHalf>
          <Carousel
            interval={4000}
            indicatorContainerProps={{
              style: {
                paddingBottom: "2rem",
                width: "100%",
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                backgroundColor: "#34255C",
                width: "5rem",
                borderRadius: "8px",
                color: "#34255C",
              },
            }}
          >
            {items.map((item, i) => {
              return (
                <S.ImgContainer key={`image_${i}`}>
                  <img
                    loading="lazy"
                    src={whyChooseUsImagesUrl[i]}
                    alt={item?.decription}
                    title={item?.text}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "12px",
                      objectFit: "cover",
                      overflow: "hidden",
                    }}
                  />
                </S.ImgContainer>
              );
            })}
          </Carousel>
        </S.RightHalf>
      </S.AboutUsContainer>
      <S.MwebAboutUsContainer>
        <S.Heading>Why choose us?</S.Heading>
        <Carousel
          interval={4000}
          navButtonsWrapperProps={{
            style: {
              top: "14.5rem",
            },
          }}
          indicatorContainerProps={{
            style: {
              paddingBottom: "2rem",
              marginTop: "2.5rem",
            },
          }}
          navButtonsAlwaysVisible={true}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: "#34255C",
              width: "5rem",
              borderRadius: "8px",
              color: "#34255C",
            },
          }}
        >
          {items.map(
            (
              item: { imageUrl: string; text: string; decription: string },
              ind
            ) => {
              return (
                <S.CarouselSection key={`carousel_${ind}`}>
                  <S.MwebImgContainer>
                    <img
                      loading="lazy"
                      src={whyChooseUsImagesUrl[ind]}
                      alt={item?.decription}
                      title={item?.text}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "12px",
                        objectFit: "cover",
                        overflow: "hidden",
                      }}
                    />
                  </S.MwebImgContainer>
                  <h2><S.SubText>{item?.text}</S.SubText></h2>
                  <h3><S.Description>{item?.decription}</S.Description></h3>
                </S.CarouselSection>
              );
            }
          )}
        </Carousel>
        <S.BookNowBtnContainer>
          <S.BookNowBtn onClick={handleBookNow}>Book Now</S.BookNowBtn>
        </S.BookNowBtnContainer>
      </S.MwebAboutUsContainer>
    </>
  );
};
export default AboutUs;
