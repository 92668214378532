import styled from "styled-components";

export const Heading = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  @media only screen and (max-width: 768px) {
    order:2;
    width:100%;
  }
`;
export const DateLocationContainer = styled.div`
width: 100%;
margin: 10px;
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--md-base, 12px);
  border: 0px solid var(--Primary-Violet-violet-10, #171129);
  background: linear-gradient(0deg, var(--Primary-Violet-violet-1, #EBE9EF) 0%, var(--Primary-Violet-violet-1, #EBE9EF) 100%), var(--Neutral-Grey-grey-1, #FFF);


  /* Set width to 400px for large screens */
  @media only screen and (min-width: 768px) {
    width: 400px;
    margin-right:50px;
  }
`
export const WidgetContainer = styled.div`
  justify-content: flex-end;
  display: flex;
   @media only screen and (max-width: 768px)
   {
    justify-content: center;
    width:100%;
    order:1;
   }


`
export const DateLocationRow = styled.div`
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 2px;
flex: 1 0 0;
`
export const LocationText = styled.div`
color: var(--Neutral-Grey-grey-13, #101010);

/* Paragraph/XS/P-XS-Medium */
font-family: "DM Sans", sans-serif;
font-size: var(--Font-Size-Paragraph-p-xs, 16px);
font-style: normal;
font-weight: 500;
line-height: var(--Line-Height-Paragraph-xs, 20px); /* 133.333% */

`
export const DateText = styled.div`
color: var(--Neutral-Grey-grey-8, #646464);

/* Paragraph/XS/P-XS */
font-family: "DM Sans", sans-serif;
font-size: var(--Font-Size-Paragraph-p-xs, 16px);
font-style: normal;
font-weight: 400;
line-height: var(--Line-Height-Paragraph-xs, 20px); /* 133.333% */
`
export const RightViewSectionContainer = styled.div`
  @media only screen and (max-width: 786px) {
    // padding: 2rem 1rem;
  }
`;
export const RightViewSectionView = styled.div`
  @media only screen and (max-width: 786px) {
    padding: 0.5rem 0.5rem 0rem 0.5rem;
  }
`;

export const BackButtonContainer = styled.div`
  flex: 0.5;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
  @media only screen and (max-width: 786px) {
    margin-bottom: 0.5rem;
  }
`;

export const BackButtonTxt = styled.div``;

export const CarouselView = styled.div``;

export const Footer = styled.div`
  background-color: #edecea;
  padding: 2rem 4rem 4rem 4rem;

  @media only screen and (max-width: 786px) {
    padding: 2rem 2rem;
  }
`;

export const FooterText = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;

  @media only screen and (max-width: 786px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const FooterSubText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin: 1rem 0;

  @media only screen and (max-width: 786px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const LinkContainer = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1rem;
  
  @media only screen and (max-width: 786px) {
    margin-top: 0.3rem;
    padding-bottom: 1rem;
  }
`;

export const LinkHeading = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
`;

export const RightViewLinkContainer = styled.div`
  background-color: #edecea;
  padding: 1rem;
`;
export const TheaterContainer = styled.div`
 display: flex;
 flex-wrap: wrap;
  gap: 16px;
  justify-content: center;

`
export const PayBookButton = styled.div`
  width: 100%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adds shadow effect */
  position: sticky; /* Stick to the bottom */
  bottom: 0; /* Align it to the bottom of the screen */
  left: 0; /* Ensure it spans from left edge */
  right: 0; /* Ensure it spans to the right edge */
  background-color: white; /* Optional: set background color */
  padding-top:10px;
  padding-bottom:10px;
  z-index: 100;
  

`
export const RemainingAmountText = styled.div`
width: 95%;
margin-left:2.5%;
margin-right: 2.5%;
    color: grey;
    font-size: 12px;
    margin-top:5px;
    margin-bottom:
`
export const BookButtonContainer = styled.div`
width: 95%;
margin-left:2.5%;
margin-right: 2.5%;
  border-radius: 10px;
  background-color: #34265c;
  color: white;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  
`
export const UpdateButton = styled.div`
  background-color: #34265c;
  border-radius: 6px;
  padding:5px;
  color: #ffffff;
`
export const WidgetHeadingContainer = styled.div`
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 786px) {
    flex-direction: column;
  }

`