import React, { useEffect, useState } from "react";
import Banner from "../../Home/screens/Banner/Banner";
import CopyRight from "../../Home/screens/CopyRight/CopyRight";
import Footer from "../../Home/screens/Footer/Footer";
import Header from "../../Home/screens/Header/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getCities,
  serviceAvailableAtCitiesSelector,
} from "../../../redux/selectors/webConfigSelector";
import { fetchWebConfig } from "../../../apis/ConfigAPI";
import { setWebConfig } from "../../../redux/actions/webConfigActions";
import {
  resetGalleryImages,
  setGalleryImages,
} from "../../../redux/actions/blogsActions";
import { getGalleryImage } from "../../../redux/selectors/blogsSelector";
import ReactPlayer from "react-player";
import { PROD } from "../../../endPointsConfig";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { IoCloseOutline } from "react-icons/io5";
import * as S from "./GalleryPage.style";
import { Helmet, HelmetProvider } from "react-helmet-async";
import seoStrings from "../../../seoSrings";
import { helmetJsonLdProp } from "react-schemaorg";
import { MediaGallery } from "schema-dts";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  border: "16px solid #fffff",
  borderRadius: 4,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  fontFamily: "Montserrat",
};

const GalleryPage = () => {
  const dispatch = useDispatch();
  const isDweb = useMediaQuery("(min-width:786px)");
  const cities = useSelector(getCities) || [];
  const citiesLocationData = useSelector(serviceAvailableAtCitiesSelector);
  const reduxGalleryImages = useSelector(getGalleryImage) || {};

  const [isActiveCity, setActiveCity] = useState(cities[0]);

  const [locationsOptions, setLocationsOptions] = useState<any>([]) || [];
  const [locations, setLocations] = useState<any>([]) || [];
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [activeImg, setActiveImg] = useState<any>();
  const [activeImgType, setActiveImgType] = useState<any>();

  const isMweb = useMediaQuery("(max-width:786px)");
  const isSmallMweb = useMediaQuery("(max-width:478px)");

  const handleActiveCity = (event: any) => {
    setActiveCity(event);
  };

  useEffect(() => {
    dispatch(resetGalleryImages());
    fetchWebConfig()
      .then((response) => {
        dispatch(setWebConfig(response));
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  useEffect(() => {
    for (let city in citiesLocationData) {
      if (
        citiesLocationData[city]?.cityName?.toLowerCase() ===
        isActiveCity?.toLowerCase()
      ) {
        let tempLocations = [];
        let cityLocations = citiesLocationData[city]?.locations;
        for (let i = 0; i < cityLocations.length; i++) {
          tempLocations.push({
            label: cityLocations[i].locationName,
            value: cityLocations[i].locationName,
          });
        }
        setLocationsOptions(tempLocations);
        setLocations(cityLocations);
      }
    }
  }, [isActiveCity]);

  const handleActiveLocation = (e: any) => {
    dispatch(resetGalleryImages());

    for (let i = 0; i < locations.length; i++) {
      if (locations[i]?.locationName === e?.value) {
        let codes = locations[i]?.code || [];
        for (let i = 0; i < codes.length; i++) {
          let theaterCode = codes[i];
          const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
          fetch(`${PROD}/getMediaUrls?theaterCode=${theaterCode}`,
            {
              method: "GET",
              headers: {
                  "sessionId": sessionId  // Fallback value ensures this is always a string
              }
          }
          )
            .then((response) => {
              return response.json();
            })
            .then((response) => {
              dispatch(setGalleryImages({ theaterCode, response }));
            })
            .catch((err) => {
              setError(err);
            });
        }
      }
    }
    setActiveCity(isActiveCity);
  };

  useEffect(() => {
    if (locations[0]?.locationName) {
      let codes = locations[0]?.code || [];
      for (let i = 0; i < codes.length; i++) {
        let theaterCode = codes[i];
        const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
        fetch(`${PROD}/getMediaUrls?theaterCode=${theaterCode}`,
            {
              method: "GET",
              headers: {
                  "sessionId": sessionId  // Fallback value ensures this is always a string
              }
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            dispatch(setGalleryImages({ theaterCode, response }));
          })
          .catch((err) => {
            setError(err);
          });
      }
    }
  }, [locations]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleImageClick = (image: any) => {
    if (image.type === "vdeo") {
      setActiveImgType("vdeo");
    } else {
      setActiveImgType("image");
    }
    setOpen(true);
    setActiveImg(image);
  };

  return (
    <>
      <HelmetProvider>
        <Helmet
          script={[
            helmetJsonLdProp<MediaGallery>({
              "@context": "https://schema.org",
              "@type": "MediaGallery",
              "name": "Binge Town Media Gallery",
              "description": "Explore our theaters decoration photos, and videos.",
              "url": "https://thebingetown.com/gallery",
              "image": [
                //"https://thebingetown.com/trailer1-thumbnail.jpg",
              ],
              "video": [
                // {
                //     "@type": "VideoObject",
                //     "name": "Movie Trailer 1",
                //     "description": "Official trailer for Movie 1",
                //     "thumbnailUrl": "https://thebingetown.com/trailer1-thumbnail.jpg",
                //     "contentUrl": "https://thebingetown.com/trailer1.mp4"
                // },
                // {
                //     "@type": "VideoObject",
                //     "name": "Behind the Scenes",
                //     "description": "Behind-the-scenes footage from Movie 2",
                //     "thumbnailUrl": "https://thebingetown.com/behind-scenes-thumbnail.jpg",
                //     "contentUrl": "https://thebingetown.com/behind-scenes.mp4"
                // }
                // Add more video objects as needed
            ]
            }),
          ]}
        >
          <title>{seoStrings['gallery']['title']}</title>
          <meta
                name="description"
                content={seoStrings['gallery']['description']}
            />
          <meta
                name="keywords"
                content={seoStrings['gallery']['keyword']}
            />
        </Helmet>
      </HelmetProvider>
      <Header />
      <Banner />
      <S.GalleryPageContainer>
        <S.Heading>Gallery</S.Heading>
        <S.LocationSelection>
          {cities &&
            cities.length > 0 &&
            cities.map((city: any) => {
              return (
                <React.Fragment key={city}>
                  {isActiveCity === city ? (
                    <S.SelctedLocation
                      onClick={(event) => handleActiveCity(city)}
                    >
                      {`${city.charAt(0).toUpperCase()}${city.slice(1)}`}
                    </S.SelctedLocation>
                  ) : (
                    <S.Location onClick={() => handleActiveCity(city)}>
                      {`${city.charAt(0).toUpperCase()}${city.slice(1)}`}
                    </S.Location>
                  )}
                </React.Fragment>
              );
            })}
        </S.LocationSelection>
        <S.LocationForm>
          <S.LabelBox>Location : </S.LabelBox>
          <Select
            placeholder="Select here"
            options={locationsOptions}
            defaultValue={{ label: "Koramangala", value: "Koramangala" }}
            onChange={handleActiveLocation}
            styles={{
              menuList: (styles) => ({
                ...styles,
                padding: 0,
              }),
              control: (styles) => ({
                ...styles,
                backgroundColor: "white",
                display: "flex",
                flex: 1,
                width: isDweb ? "19rem" : "",
                height: "3rem",
                borderRadius: "12px",
                paddingBottom: 0,
                paddingTop: 0,
              }),
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  // ...styles,
                  backgroundColor: "#edecea",
                  padding: "0.5rem",
                  height: "2rem",
                  display: "flex",
                  alignItems: "center",
                  borderBotom: "1px solid #cacaca",
                };
              },
            }}
          />
        </S.LocationForm>
        {Object.entries(reduxGalleryImages).map(
          ([theaterCode, images]: any) => {
            return (
              <S.ReviewContainer key={theaterCode}>
                <S.TheaterHeading>{images[0]?.theaterName}</S.TheaterHeading>
                <S.TheaterGalleryContainer>
                  {images &&
                    images.length > 0 &&
                    images.map((image: any) => {
                      return (
                        <S.ImgContainer
                          key={image.id}
                          onClick={(e) => handleImageClick(image)}
                        >
                          {image?.type === "vdeo" ? (
                            <ReactPlayer
                              url={image.url}
                              width={
                                isDweb
                                  ? "28rem"
                                  : isSmallMweb
                                  ? "100%"
                                  : "10rem"
                              }
                              height={
                                isDweb ? "18rem" : isSmallMweb ? "100%" : "7rem"
                              }
                              borderRadius={"20px"}
                              controls
                              onPlay={() => handleImageClick(image)}
                              muted={true}
                            />
                          ) : (
                            <img
                              src={image.url}
                              alt="gallary_image"
                              title="gallary_image"
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "20px",
                                objectFit: "cover",
                                overflow: "hidden",
                              }}
                            />
                          )}
                        </S.ImgContainer>
                      );
                    })}
                </S.TheaterGalleryContainer>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="parent-modal-title"
                  aria-describedby="parent-modal-description"
                >
                  <Box
                    sx={{
                      ...style,
                      width: isSmallMweb ? "75%" : isMweb ? 400 : 700,
                    }}
                  >
                    <S.ModalContainer>
                      <S.ModalHeading>
                        <S.ModalHeadingTxt></S.ModalHeadingTxt>
                        <IoCloseOutline size={24} onClick={handleClose} />
                      </S.ModalHeading>
                      {activeImgType === "vdeo" ? (
                        <ReactPlayer
                          url={activeImg?.url}
                          controls
                          width={isDweb ? "700px" : "95%"}
                          height={isDweb ? "500px" : "95%"}
                          style={{
                            borderRadius: "20px",
                            objectFit: "cover",
                            overflow: "hidden",
                          }}
                        />
                      ) : (
                        <img
                          src={activeImg?.url}
                          alt="gallary_image"
                          title="gallary_image"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "20px",
                            objectFit: "cover",
                            overflow: "hidden",
                          }}
                        />
                      )}
                    </S.ModalContainer>
                  </Box>
                </Modal>
              </S.ReviewContainer>
            );
          }
        )}
      </S.GalleryPageContainer>

      <Footer />
      <CopyRight />
    </>
  );
};
export default GalleryPage;
