import Header from "../../../../../Home/screens/Header/Header";
import styled from "styled-components";
import RightViewSection from "../RightViewSection/RightViewSection";
import CopyRight from "../../../../../Home/screens/CopyRight/CopyRight";
import { HiArrowLeft } from "react-icons/hi";
import { CAKE } from "./CAKE";
import BookingSummary from "../BookingSummery/BookingSummary";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Tick from "../../../../../../assets/images/Tick.png";
import {
  getActiveCakes,
  getBookingSummary,
  getCouponInfo,
  getIsCouponAppliedSuccessfully,
  getSelectedTheater,
  getSubTotalAmount,
} from "../../../../../../redux/selectors/bookingSelector";
import {
  setActiveCakes,
  setBookingSummary,
  setBookingSummaryCake,
  setSubtotalAmount,
} from "../../../../../../redux/actions/bookingActions";
import * as S from "./CakeSelection.style";
import { getUpdateBookingDetail } from "../../../../../../redux/selectors/blogsSelector";
import {store} from "../../../../../../store";

const ImageContainer = styled.div`
  background-color: #ffffff;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  box-shadow: 0px 10px 35px 0px #575c8a1f;

  @media only screen and (max-width: 786px) {
    width: 130px;
    height: 130px;
  }
`;

const CakeSelection = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const bookingSummary = useSelector(getBookingSummary);
  const activeCakesSelector = useSelector(getActiveCakes);
  const updateBookingDetail = useSelector(getUpdateBookingDetail) || {};

  const selectedTheater = useSelector(getSelectedTheater);
  const CAKES = selectedTheater?.cake || [];

  const [activeCakes, setActiveCake] = useState<String[]>(
    activeCakesSelector || []
  );

  const isCouponApplied = useSelector(getIsCouponAppliedSuccessfully);
  const subTotalAmount = useSelector(getSubTotalAmount) || 0;
  const coupon = useSelector(getCouponInfo);
  const { code: couponCode, price: couponPrice } = coupon || {};

  // const [bookingSummaryData, setBookingSummaryData] =
  //   useState<any>(bookingSummary);

  const [bookingSummaryCakes, setBookingSummaryCakes] = useState<any>(
    bookingSummary.cake || []
  );
  // const [bookingSummaryAddons, setBookingSummaryAddons] = useState<any>(
  //   bookingSummary.addOns || []
  // );
  const { subTotal, advancePayment } = bookingSummary || {};
  const [showBookingSummary, setShowBookingSummary] = useState<Boolean>(false);
  const [hoverCake, setHoverCake] = useState("");

  const handleNext = () => {
    navigate("choose_decoration");
  };

  const handleSelectedCake = (cake: { name: String; price: number }) => {
    if (activeCakes?.includes(cake.name)) {
      let removeCake = activeCakes?.filter(
        (activeCake: String) => activeCake !== cake.name
      );
      setActiveCake([...removeCake]);

      const filteredCakes = bookingSummaryCakes?.filter(
        (summaryCake: { name: String; price: number }) =>
          summaryCake.name !== cake.name
      );
      setBookingSummaryCakes([...filteredCakes]);
    } else {
      setActiveCake([...activeCakes, cake.name]);
      setBookingSummaryCakes([...bookingSummaryCakes, cake]);
    }
  };

  useEffect(() => {
    if (!updateBookingDetail.cake || updateBookingDetail.cake.length === 0)
      return;

    const cakeNames = updateBookingDetail?.cake
      ?.split(",")
      ?.map((cake: any) => {
        return cake?.split?.("(")?.[0]?.trim();
      });

    const filteredCakes = [...CAKES]?.filter(
      (cake: { name: String; price: number; iconUrl: string; index: number }) =>
        cakeNames?.includes(cake.name)
    );
    setBookingSummaryCakes([...filteredCakes]);
  }, [updateBookingDetail.cake]);

  useEffect(() => {
    dispatch(setBookingSummaryCake(bookingSummaryCakes));
    store.dispatch(setSubtotalAmount());
  }, [bookingSummaryCakes]);

  useEffect(() => {
    dispatch(setActiveCakes(activeCakes));
  }, [activeCakes]);

  const handleMouseOver = (cake: any) => {
    setHoverCake(cake.name);
  };

  const handleShowBookingSummary = () => {
    setShowBookingSummary(!showBookingSummary);
  };

  return (
    <>
    <title>The Binge Town | Cake Selection</title>
      <Header />
      <S.BookingOverviewContainer>
        <S.LeftViewSectionContainer>
          <S.BackButtonContainer onClick={() => navigate(-1)}>
            <HiArrowLeft size={24} />
            <S.BackButtonTxt>Back</S.BackButtonTxt>
          </S.BackButtonContainer>

          <S.Overview>
            <S.HeadingContainer>
              <S.HeadingTxt>Choose Cake (&#xBD; kg)</S.HeadingTxt>
              <S.OptionalContainer>(optional)</S.OptionalContainer>
            </S.HeadingContainer>

            <S.CakeView>
              {CAKES &&
                CAKES.length > 0 &&
                CAKES.map((cake: { name: string; price: number }) => {
                  return (
                    <S.OccasionView
                      key={cake.name}
                      onClick={() => handleSelectedCake(cake)}
                      onMouseOver={() => handleMouseOver(cake)}
                    >
                      {activeCakes &&
                      activeCakes.length > 0 &&
                      activeCakes?.includes(cake.name) ? (
                        <ImageContainer>
                          <S.TickImageContainer src={Tick} alt="tick" />
                          <img
                            src={CAKE[cake.name as keyof typeof CAKE].imgUrl}
                            alt={cake.name}
                            title={cake.name}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              overflow: "hidden",
                              top: "-3.3rem",
                              position: "relative",
                            }}
                          />
                        </ImageContainer>
                      ) : (
                        <>
                          <ImageContainer>
                            <img
                              src={CAKE[cake.name as keyof typeof CAKE].imgUrl}
                              alt={cake.name}
                              title={cake.name}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                overflow: "hidden",
                              }}
                            />
                          </ImageContainer>
                        </>
                      )}
                      <S.OccasionTitle>
                        {CAKE[cake.name as keyof typeof CAKE].name}
                      </S.OccasionTitle>
                      <S.OccasionPrice>&#8377; {cake.price}</S.OccasionPrice>
                    </S.OccasionView>
                  );
                })}
            </S.CakeView>
            <S.OptionalContainer>(Note:- The cake images provided are for representation purposes only; actual cakes may vary.)</S.OptionalContainer>
          </S.Overview>

          <S.MwebFooterContainer>
            <S.PriceBreakupSummaryContainer>
              <S.PriceBreakupHeader>
                <S.PriceBreakupTotal>Total</S.PriceBreakupTotal>
                <S.PriceBreakupPrice>
                  &#8377;
                  {isCouponApplied
                    ? subTotalAmount - couponPrice
                    : subTotalAmount}
                </S.PriceBreakupPrice>
              </S.PriceBreakupHeader>
              <S.PriceBreakup>
                <S.BookingSummaryHeading onClick={handleShowBookingSummary}>
                  <S.BookingSummaryTxt> Booking summary</S.BookingSummaryTxt>
                  {showBookingSummary ? (
                    <MdOutlineKeyboardArrowUp size={24} />
                  ) : (
                    <MdOutlineKeyboardArrowDown size={24} />
                  )}
                </S.BookingSummaryHeading>
              </S.PriceBreakup>
              <S.PriceBreakupDetails>
                {showBookingSummary ? <BookingSummary /> : null}
              </S.PriceBreakupDetails>
            </S.PriceBreakupSummaryContainer>
            <S.BookNowBtn
              onClick={handleNext}
              type="submit"
              // disabled={!isFormValid}
            >
              Next step
            </S.BookNowBtn>
          </S.MwebFooterContainer>
        </S.LeftViewSectionContainer>
        <S.RightViewSectionContainer>
          {/* <RightViewSection></RightViewSection> */}
          <BookingSummary fromPage="cake" />
        </S.RightViewSectionContainer>
      </S.BookingOverviewContainer>
      <CopyRight />
    </>
  );
};

export default CakeSelection;
