import Luxury_1 from "../../../../assets/images/Luxury_1.jpeg";
import FogEntry from "../../../../assets/images/Our services/FogEntry.webp";
import Gifts from "../../../../assets/images/Our services/Gifts.webp";
import Photoshoot from "../../../../assets/images/Our services/Photoshoot.webp";

export const SERVICES = [
  {
    title: "Private movie screening",
    imgUrl: Luxury_1,
    desc: "You can book our private theaters for watching your favorite movies and shows on the big HD screen with surround sound and comfortable sittings. Our screen sizes vary from 135 inch to 180 inch and speakers vary from 600 W to 1000 W. Customers have to use their own OTT accounts or downloaded files to stream the content. We support all major OTT Apps and help in setting things up for you. Please note that we do not support Pen Drives/Hard Disks.",
    isBestSeller: false,
  },
  {
    title: "Decoration",
    imgUrl: Luxury_1,
    desc: "All our Private theaters are decorated to make them a great place for celebrations of special events like Birthdays, anniversaries, bride to be, romantic date, etc. Each and every private theater of The Binge Town has a different and unique theme of decoration. In some theatres decorations is mandatory and included in pricing while in others we provide without decoration pricing options also. Decorations are as per reference images with slight customizations specific to the event.",
    isBestSeller: false,
  },
  {
    title: "Snacks and beverages",
    imgUrl: Luxury_1,
    desc: "A celebration is incomplete without delectable food, and at The Binge Town, we take pride in offering a diverse and mouthwatering menu at all our branches. Menu varies from branch to branch. Some of the most commmonly available items in our menu are: French fries, Pizza, Soft drinks, Nuggets, Milk Shakes, etc. Customers can also order food from outside. Please note that Snacks and beverages are not included in the booking and have to be ordered separately at the theater.",
    isBestSeller: false,
  },
  {
    title: "Cakes",
    imgUrl: Luxury_1,
    desc: "No need to hassle for arranging that perfect cake for your birthday, anniversary or any other event. We provide a diverse range of cake options that are tasty as well as economical. After your celebration, we also pack your cake properly for you to carry. Cakes can be added to your event while booking from the website with extra charges depending on the flavour",
    isBestSeller: false,
  },
  {
    title: "Bouquet and other gifts",
    imgUrl: Gifts,
    desc: "Want to make your special ones feel even more special by gifting them? We provide bouquet, photo frames, soft toys, showpieces, and many more gifting options in our website and at the theater for you to select the perfect gift for your loved ones. Kindly note that some gifts can be added directly from the website while some options are available only at the theater and can be purchased there directly",
    isBestSeller: false,
  },
  {
    title: "Fog entry",
    imgUrl: FogEntry,
    desc: "Begin your event with a dreamy entry which feels like walking over the clouds. We use dry ice to fill the theater with cloud like Fog during the entry which makes it really heavenly and magical. Then gas is completely safe and non-suffocation, so even kids can enjoy it freely. Pricing for Fog entry starts at RS.350 and you can add multiple Fog entries in your event as per your requirement.",
    isBestSeller: true,
  },
  {
    title: "Photoshoot",
    imgUrl: Photoshoot,
    desc: "Who does not want to record beautiful memories of special days in form of high quality photos which can also be shared with friends and family on social media? Almost all our branches offer DSLR photography service for as low as RS.300 for 15 minutes. We share the soft copy of the photos within 12 hrs through Google drive link.",
    isBestSeller: true,
  },
];
