import styled from "styled-components";

export const TheatersContainer = styled.div`
  border-radius: 1.5rem;
  width:28.125rem;

  margin-top:-1rem;
  
  @media only screen and (max-width: 913px) {
    width: 23.44rem;

  }

  @media only screen and (max-width: 769px) {
    width: 21.875rem;
  }

  @media only screen and (max-width: 768px) {
    width: 23.44rem;
  }
`;

export const TheaterView = styled.div`

  @media only screen and (max-width: 786px) {
    flex-direction: column;
    width:100%;
  }
`;

export const LeftView = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  .carousel-control-prev, .carousel-control-next {
    height: auto; /* Adjust the height to auto to prevent full height overlay */
    position: absolute; /* Keep the buttons in their position */
    top: 50%; /* Vertically center the buttons */
    transform: translateY(-50%);
`;

export const CarouselView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  flex: 1;
  position: relative;
  height: fit-content;
  pointer-events: none;

`;

export const ViewSampleVideoContainer = styled.div`
  display: flex;
padding:0.125rem 0.25rem;
justify-content: center;
align-items: center;
gap: 0.125rem;
position: absolute;
left: 0.75rem;
bottom: 0.75rem;
border-radius: var(--xs, 0.375rem);
background: var(--Primary-Orange-orange-1, #FFF4E6);
z-index: 1000;
`;

export const ViewSampleVideoIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const ViewSampleVideoTxt = styled.div`
  flex: 1;
  font-weight: 500;
  font-size: 0.9375rem;
  color: black;
  @media only screen and (max-width:913px) {
    font-size: 0.6875rem;
  }
`;

export const RightView = styled.div`
  flex: 1;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const FooterView = styled.div`
  padding: 1rem;

  @media only screen and (max-width: 786px) {
    padding: 0 1rem;
  }
`;

export const TimeSlots = styled.div`
  @media only screen and (max-width: 913px) {
    font-size: 0.8125rem;
  }
`;

export const TimeTxt = styled.div`
  flex: 1;
  font-weight:400;
  font-size: 0.685rem;
  font-family: "DM Sans", sans-serif;
  line-height:1rem;
  @media only screen and (max-width: 350px) {
    font-size: 0.625rem;
  }
`;

export const TimeSlotLabel = styled.div`
  margin: 0.5rem 0rem;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
`;

export const MwebTimeSlotLabel = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
`;

export const TimeSlotsAvailable = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const MwebTimeSlotsAvailable = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  flex: 1;
  display: none;
  @media only screen and (min-width: 786px) {
    display: none;
    margin: 0rem;
  }
`;

export const BookingAction = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 1.1rem;

  @media only screen and (max-width: 786px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const Title = styled.h3`
  font-weight: 600;
  line-height:1.30rem;
  font-size: 1.05rem;
  margin-right: 1rem;
  align-items:center;
  flex-wrap: nowrap;
  font-family: "DM Sans", sans-serif;
`;
export const TitleContainer = styled.div`
  display: inline-flex;
  justify-content:space-between;
`;

export const PriceContainer = styled.div`
  line-height:1rem;
`;
export const PriceContainerText = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-family: "DM Sans", sans-serif;
  display: flex;
align-items: end;
gap: 0.25rem;
align-self: stretch;
`;

export const Price = styled.span`
  font-size: 1.125rem;
  font-weight: 600;
  font-family: "DM Sans", sans-serif;
  line-height:1.25rem;

`;

export const ExtraChargeContainer = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
`;

export const ExtraChargePrice = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  color:grey;
  font-family: "DM Sans", sans-serif;
  line-height:1rem;
`;

export const ExtraChargeText = styled.span`
  font-size: 0.625rem;
  font-weight: 400;
`;

export const TaxInclude = styled.span`
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1rem;
  font-family: "DM Sans", sans-serif;
  align-content: center;
`;

export const MaxLimit = styled.div`
  padding: 0.4rem 1rem;
  background-color: #e9e3f5;
  border-radius:1.5rem;
`;

export const MoreDetails = styled.div`
  font-size: 0.875rem;
  gap: 0.5rem;
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;

  @media only screen and (max-width: 786px) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0.5rem;
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  font-size: 0.875rem;
`;

export const MoreInfoAboutSlots = styled.div`
  flex-wrap: wrap;
  flex-shrink: 0
`;

export const SlotsAvailable = styled.div`
display: flex;
align-items: center;
font-family: "DM Sans", sans-serif;
font-size: var(--Font-Size-Paragraph-p-2xs, 0.75rem);
  background-color: #f9fdf9;
  padding:0.12rem 0.375rem;
  border-radius: 0.75rem;
  color:#1c9b21;
  font-weight: 400;
  border: 1px solid #1c9b21;
  @media only screen and (max-width:913px) {
    border-radius: 0.5rem;
  }

`;

export const MinSlotsAvailable = styled.div`
display: flex;
align-items: center;
font-family: "DM Sans", sans-serif;
font-size: var(--Font-Size-Paragraph-p-2xs, 0.75rem);
  background-color: lavenderblush;
  padding: 0.12rem 0.375rem;
  border-radius: 0.75rem;
  color:red;
  font-weight: 400;
  border: 1px solid red; /* Add border width and style */
  @media only screen and (max-width:913px) {
    border-radius: 0.5rem;
  }
`;
export const InfoText = styled.div`
color: #34265c;
font-size: 0.875rem;
font-weight: 400;
 margin-left: 0.25rem;
 margin-right: 1rem;
 font-family: "DM Sans", sans-serif;
 @media only screen and (max-width:913px){
 font-size: 0.8125rem;
 margin-right: 0.75rem;
 line-height:1rem;

 }
`
export const TimeSlotHeading = styled.div`
  font-size: 0.875rem;
  font-weight:550;
 font-family: "DM Sans", sans-serif;
  margin-bottom: -0.125rem;
  line-height:1.5rem;
`
export const TimeSlot = styled.div`
  color: #34265c;
  border-radius : 0.75rem;

`
export const CurrentViewers = styled.div`
  font-size: 0.875rem;
  color: #ff5208;
  background-color: #ffe6db;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  font-weight: 500;

  @media only screen and (max-width: 786px) {
    padding: 0.5rem 0.3rem;
  }
`;

export const Details = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  display: flex;
  gap: 1rem;
  display: flex;
`;

export const MoreDtail = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  display: flex;
  gap: 1rem;
`;

export const AddressLabel = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
`;

export const AddressText = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
`;

export const Address = styled.div``;

export const AddressDirection = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PeopleInfo = styled.div`
  display: flex;
  gap: 0.2rem;
  flex-wrap: wrap;

  @media only screen and (max-width: 786px) {
    font-size: 0.8125rem;
  }
`;

export const TipContainer = styled.div`
  @media only screen and (max-width: 786px) {
    font-size: 0.75rem;
    line-height: 0.875rem;
    text-align: center;
  }
`;
export const PriceBookButtonContainer = styled.div`
  display: flex;
  align-items: center;

`
export const BookButtonContainer = styled.div`
margin-left: 1.25rem;
  border-radius: 0.625rem;
  background-color: #00008b;
  color: white;
  font-size: 0.875rem;
  padding: 0.625rem;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  @media only screen and (max-width:913px) {
    font-size: 0.8125rem;
    margin-left:0.625rem;
  }
  
`
export const LocationShareButton = styled.div`
 border-radius: 0.5rem;
 background-color:#d3d3d3;
 padding: 0.3125rem;
 margin-right: 0.625rem;
 display: flex;
 justify-content:center;
 margin-top:0.125rem;
 cursor: pointer;
 height: fit-content;
`
export const TitleButtonContainer = styled.div`
    position: relative;
    right: 0px;
    display: flex;
    justify-content: end;
`
export const TimeSlotContainer = styled.div`
  width:100%;
 display: flex;
 align-items: center;
 flex-wrap: wrap;
`
export const LocationSlotContainer = styled.div`
  display: flex;
  align-items: center;
`
export const LocationInfo = styled.div`
 margin-left: 0.125rem;
 margin-right: 0.5rem;
 color: grey;
 font-family: "DM Sans", sans-serif;
 font-size: 0.75rem;
 font-weight: 400;
 line-height:1rem;
 cursor: pointer;
`
export const InfoContainer = styled.div`
  display: flex;
align-items : center;
margin-bottom: 0.25rem;
`
export const TitleLocationContainer = styled.div`
`
export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0.625rem;
  background-color: #34265c;
  padding: 0.1rem 0.5rem;
  top:3rem;
  left:83%;
  position: relative;
  width: 17%;
  gap: 0.5rem;
  z-index: 10;
  cursor: pointer;

  @media only screen and (max-width: 913px) {
  }

`
export const RatingText = styled.div`

  color:#ffffff;
  font-size: 0.875rem;
  cursor: pointer;
`
export const SelectTimeSlot = styled.div`
`
export const AllInfoContainer = styled.div`
`