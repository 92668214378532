import styled from "styled-components";
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it's above other content */
  align-items: flex-start;
`;
export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  margin-bottom: 10px;

  &:hover {
    color: #000;
  }
`;
export const ModalContent = styled.div`
position: relative;
width:400px;
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1001; /* Ensure it's above the overlay */
  margin-top:20px;
  @media only screen and (max-width: 768px) {
    width:100%;
  }
`;
export const ChooseRow = styled.div`
margin-top:10px;
        margin-left: 0px;
        justify-content: center;
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
            width: 100%;
        }               
`
export const RowDate = styled.div`
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 5px;
    display: flex;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
`
export const SelectLocationDate = styled.div`
    margin-top: 10px;
    border: 1px solid grey;
    border-radius: 10px;
    margin-top: 10px;
    border: 1px solid grey;
    border-radius: 10px;
    width: 100%;      
`
export const SelectDateContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`
export const DateContainerText = styled.div`
    width: 85%;
    position: relative;
     padding-left: 15px;
     padding-right: 15px;
  flex: 1 0 0%; 
  padding-top: 5px;
  padding-bottom: 5px;
`
export const DateText = styled.div`
    color: grey;
    font-size: 14px;
    font-family: "DM Sans", sans-serif;
`
export const ChooseDateText = styled.div`
    color: grey;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    font-family: "DM Sans", sans-serif;
`
export const CalendarContainer = styled.div`
    width: 15%;
    align-content: center;
`
export const SearchContainer = styled.div`
    display: flex;
    width: 19%;
    background-color: #34265c;
    border-radius: 10px;
    margin-left: 10px;
    align-items: center;
    margin-top: 10px;
    justify-content: center;
`
export const SearchSmallScreen = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
         background-color: #34265c;
    border-radius: 10px;
    margin-top: 10px;
`
export const SearchText = styled.span`
    color: white;
`
export const LocationContainer = styled.div`
    line-height: normal;
`
export const LocationText = styled.div`
    font-size: 14px;
    color: grey;
`
export const ChooseLocationText = styled.div`
    font-size: 12px;
    color: grey;
`
export const CalendarWrapper = styled.div`
  position: absolute; /* Position the calendar absolutely */
  top:-5%;
  left: 35%; /* Align it with the parent component */
  z-index: 1200; /* Ensure it's on top of other elements */
  border-radius: 8px; /* Optional: Add some rounding for a better look */
  padding: 10px;
  max-width:350px;
  @media only screen and (max-width: 768px) {
       top:0%;
       left:10%;
    } 

  .react-calendar {
    width: 100%;
    border-radius: 10px;
    overflow: hidden; /* Ensures child elements don't overflow beyond the border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .react-calendar__navigation {
      .react-calendar__navigation__next2-button {
        display: none;
      }

      .react-calendar__navigation__prev2-button {
        display: none;
      }
    }
    .react-calendar__tile {
      border-radius: 0;
    }
    @media (max-width: 768px) {
      .react-calendar {
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
      .react-calendar__tile {
        border-radius: 0;
      }
    }
  }
`;