import Banner from "../../Home/screens/Banner/Banner";
import CopyRight from "../../Home/screens/CopyRight/CopyRight";
import Footer from "../../Home/screens/Footer/Footer";
import Header from "../../Home/screens/Header/Header";
import Contact_us from "../../../assets/images/Contact_us.webp";
import { SlLocationPin } from "react-icons/sl";
import { ImWhatsapp } from "react-icons/im";
import { MdOutlineEmail } from "react-icons/md";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { getContactUsPage } from "../../../redux/selectors/webConfigSelector";
import * as S from "./ContactUsPage.style";
import { Helmet, HelmetProvider } from "react-helmet-async";
import seoStrings from "../../../seoSrings";
import { helmetJsonLdProp } from "react-schemaorg";
import { ContactPage } from "schema-dts";

const ContactUsPage = () => {
  const matches = useMediaQuery("(max-width: 400px)");
  const iconSize = matches ? "20" : "24";
  const contactUsPageInfo = useSelector(getContactUsPage);

  const { contactUsImageURL, whatsappNumber, emailAddress, officeLocation } =
    contactUsPageInfo;

  return (
    <>
      <HelmetProvider>
        <Helmet script={[
          helmetJsonLdProp<ContactPage>({
            "@context": "https://schema.org",
            "@type": "ContactPage",
            "name": "Contact The Binge Town",
            "description": "Get in touch with us for private theater bookings.",
            "mainEntity": {
                "@type": "Organization",
                "name": "The Binge Town",
                "url": "https://thebingetown.com/"
            }            
          }),
        ]}
        >
          <title>{seoStrings?.["contactUs"]?.["title"]}</title>
          <meta
            name="description"
            content={seoStrings?.["contactUs"]?.["description"]}
          />
          <meta
            name="keywords"
            content={seoStrings?.["contactUs"]?.["keyword"]}
          />
        </Helmet>
      </HelmetProvider>
      <Header />
      <Banner />
      <S.ContactUsPageContainer>
        <S.Heading>Contact us</S.Heading>

        <S.ReviewContainer>
          <S.LeftFirstHalf>
            <img
              src={contactUsImageURL || Contact_us}
              alt="contact us"
              title="contact us"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "24px",
                objectFit: "cover",
                overflow: "hidden",
              }}
            />
          </S.LeftFirstHalf>
          <S.RightHalf>
            <S.CustomerReviewDesc>
              <S.ReachUsDetails>
                <S.ReachUsIcon>
                  <ImWhatsapp size={iconSize} />
                </S.ReachUsIcon>
                <S.ReachUsText>{whatsappNumber}</S.ReachUsText>
              </S.ReachUsDetails>

              <S.ReachUsDetails>
                <S.ReachUsIcon>
                  <MdOutlineEmail size={iconSize} />
                </S.ReachUsIcon>
                <S.ReachUsText> {emailAddress}</S.ReachUsText>
              </S.ReachUsDetails>

              <S.ReachUsDetails>
                <S.ReachUsIcon>
                  <SlLocationPin size={iconSize} />
                </S.ReachUsIcon>
                <S.ReachUsText>{officeLocation}</S.ReachUsText>
              </S.ReachUsDetails>
            </S.CustomerReviewDesc>
          </S.RightHalf>
          <S.LeftHalf>
            <img
              src={contactUsImageURL || Contact_us}
              alt="contact us"
              title="contact us"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "16px",
                objectFit: "cover",
                overflow: "hidden",
              }}
            />
          </S.LeftHalf>
        </S.ReviewContainer>
      </S.ContactUsPageContainer>

      <Footer />
      <CopyRight />
    </>
  );
};
export default ContactUsPage;
