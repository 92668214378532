import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import {store, persistor} from "./store";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

// Delay persisting state until after react-snap's pre-rendering
const isPrerendering = navigator.userAgent === 'ReactSnap';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    {!isPrerendering ? (
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    ) : (
      <BrowserRouter>
        <App />
      </BrowserRouter>
    )}
  </Provider>
);

reportWebVitals();
