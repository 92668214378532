const initialState = { blogs: {}, articleInfo: {}, phoneNumber: "" };

const blogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_BLOGS":
      return {
        ...state,
        blogs: action.payload,
      };
    case "SET_ARTICLES":
      return {
        ...state,
        articleInfo: action.payload,
      };
    case "SET_LOGIN_DETAILS":
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case "SET_UPDATE_BOOKING_DETAILS":
      return {
        ...state,
        updateBookingDetails: action.payload,
      };
    case "SET_UPDATE_BOOKING_DETAIL":
      return {
        ...state,
        updateBookingDetail: action.payload,
      };
    case "RESET_UPDATE_BOOKING_DETAIL":
      console.log("Coming here ", state);
      return {
        ...state,
        updateBookingDetails: [],
        updateBookingDetail:{}
      };
    case "SET_GALLERY_IMAGES":
      return {
        ...state,
        galleryImages: {
          ...state.galleryImages,
          [action.payload.theaterCode]: action.payload.response,
        },
      };
    case "RESET_GALLERY_IMAGES":
      return {
        ...state,
        galleryImages: {},
      };
    default:
      return state;
  }
};

export default blogsReducer;
