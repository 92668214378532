import "./City.css";
import { styled } from "styled-components";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { locationCodeMapSelector } from "../../../../../../redux/selectors/webConfigSelector";
import {
  setSelectedCity,
  setSelectedLocation,
} from "../../../../../../redux/actions/bookingActions";
import { getSelectedLocation } from "../../../../../../redux/selectors/bookingSelector";
import {store} from "../../../../../../store";

type propsTypes = {
  isFromHomePage: boolean;
  title: string;
  imgUrl: any;
  viewLocationOptions: any;
};

const CityContainer = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  margin: 2rem 0;
  // @media only screen and (max-width: 768px) {
  //   width: 80%;
  //   heigth: 80%;
  // }

  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

const CityImgContainer = styled.div`
  // flex: 1.5;
  display: flex;
  flex-direction: column;
  width: 363px;
  height: 348px;
  // background-color: red;
`;

const ViewLocationTxt = styled.div`
  // flex: 1;
  display: flex;
  text-decoration: underline;
  // background-color: red;
`;

const CityImg = styled.img`
  border-radius: 24px 24px 0 0;
  background-size: contain;
  // flex: 1;
`;

const CityTitle = styled.div`
  position: relative;
  bottom: 18%;
  left: 5%;
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
`;

const ViewLocationContainer = styled.div<{ $showViewLocation: boolean }>`
  display: flex;
  position: relative;
  justify-content: space-between;
  bottom: ${(props) => (props.$showViewLocation ? "4.5%" : "7.1%")};
  padding: 1.5rem;
  background-color: #34255c;
  color: #ffffff;
  border-radius: ${(props) =>
    props.$showViewLocation ? "0 0 0 0" : "0 0 24px 24px"};
`;

const ShowLocations = styled.div<{ $showViewLocation: boolean }>`
  // flex: 1;
  position: relative;
  // padding-top: 5rem;
  bottom: ${(props) => (props.$showViewLocation ? "4.5%" : "10%")};
  background: linear-gradient(180deg, #34255c 53.85%, #6e4ec2 100%);
  border-radius: 0 0 24px 24px;
`;

const MwebShowLocations = styled.div<{ $showViewLocation: boolean }>`
  position: relative;
  bottom: ${(props) => (props.$showViewLocation ? "14.5%" : "10%")};
  background: linear-gradient(180deg, #34255c 53.85%, #6e4ec2 100%);
  border-radius: 24px;

  @media only screen and (min-width: 786px) {
    display: none;
  }
`;

const Location = styled.div<{ $lastInd: boolean }>`
  border-radius: ${(props) => (props.$lastInd ? "0 0 24px 24px" : "")};
  // background-color: #34255c;

  flex: 1;
  display: flex;
  color: white;
  padding: 1rem 1.5rem;
  justify-content: space-between;
  align-items: center;
`;

const LocationImage = styled.img.attrs((props) => ({
  src: props.src,
}))`
  // flex: 1;
`;

const LocationTxt = styled.div`
  flex: 1;
  display: flex;
  margin-left: 1rem;
`;

const LocationDirection = styled.div`
  // flex: 1;
  display: flex;
  justify-content: flex-end;
`;

// ---

const MwebCityContainer = styled.div`
  flex: 1;
  background-color: #ffffff;
  border: 1px solid #cacaca;
  padding: 1rem;
  border-radius: 16px;
  display: flex;
  gap: 0.5rem;

  @media only screen and (min-width: 786px) {
    display: none;
  }
`;
const MwebContainer = styled.div``;

const MwebCityImgContainer = styled.div`
  flex: 0.2;
  border-radius: 12px;
`;

const MwebCityTxtContainer = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  padding: 0.4rem 0;
`;

const MwebCityContent = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

const MwebViewLocationContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 1rem;
  align-items: flex-end;
`;

const MwebViewLocationTxt = styled.div`
  flex: 1;
  display: flex;
  color: #34255c;
  text-decoration: underline;
`;

const MwebDownArrowIcon = styled.div`
  // flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const City = (props: propsTypes) => {
  const navigate = useNavigate();
  const { title, imgUrl, viewLocationOptions, isFromHomePage = false } = props;

  // const matches = useMediaQuery("(min-width:1400px)");
  // let widthStyle = matches ? "90%" : "50%";
  // let heightStyle = matches ? "90%" : "50%";

  const [showViewLocation, setShowViewLocations] = useState(false);
  const locationCodeMap = useSelector(locationCodeMapSelector);
  const selectedLocation = useSelector(getSelectedLocation);

  // const count = useSelector((state: any) => state.counter.value);
  // const dispatch = useDispatch();

  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const showViewLocations = (e: any) => {
    setShowViewLocations(!showViewLocation);
    store.dispatch(setSelectedCity(selectedLocation));
  };

  const handleLocationClicked = (location: any) => {
    if (!locationCodeMap) return;
    const locationCode = locationCodeMap[location.locationName];
    // if(locationCode === 'KBH'){
    //   navigate('/early_bird');
    //   return;
    // }
    dispatch(setSelectedLocation(location));
    store.dispatch(setSelectedCity(null, title));
    if (isFromHomePage)
      navigate(`/private-theaters-in-${title.toLowerCase()}/${location.locationName.toLowerCase()}`);
    else navigate(`/private-theaters-in-${title.toLowerCase()}/${location.locationName.toLowerCase()}`, { replace: true });
  };

  return (
    <>
      <CityContainer>
        <CityImgContainer>
          <CityImg
            src={imgUrl}
            title={title}
            alt={title}
            className="cityImg"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              overflow: "hidden",
            }}
          />
          <CityTitle>{title}</CityTitle>
        </CityImgContainer>
        <ViewLocationContainer
          onClick={showViewLocations}
          $showViewLocation={showViewLocation}
        >
          <ViewLocationTxt>View locations</ViewLocationTxt>
          {showViewLocation ? (
            <IoIosArrowUp
              size={24}
              style={{
                position: "relative",
                top: "-2px",
              }}
            />
          ) : (
            <IoIosArrowDown
              size={24}
              style={{
                position: "relative",
                top: "-2px",
              }}
            />
          )}
        </ViewLocationContainer>

        <ShowLocations $showViewLocation={showViewLocation}>
          {showViewLocation &&
            viewLocationOptions &&
            viewLocationOptions.map((option: any, ind: number) => {
              let lastInd = ind === viewLocationOptions.length - 1;

              return (
                <Location
                  $lastInd={lastInd}
                  onClick={() => handleLocationClicked(option)}
                  key={option?.locationName}
                >
                  <LocationImage
                    src={option.locationImageURL}
                    title={option.locationName}
                    alt={option.locationName}
                    style={{
                      borderRadius: "50%",
                      width: "56px",
                      height: "56px",
                      backgroundColor: "#ffffff",
                    }}
                  />
                  <LocationTxt>{option.locationName}</LocationTxt>
                  <LocationDirection>
                    <FaArrowRight size={24} />
                  </LocationDirection>
                </Location>
              );
            })}
        </ShowLocations>
      </CityContainer>

      <MwebCityContainer key={title}>
        <MwebCityImgContainer>
          <img
            src={imgUrl}
            className="cityImg"
            alt={title}
            title={title}
            style={{
              width: "56px",
              height: "56px",
              borderRadius: "12px",
              objectFit: "cover",
              overflow: "hidden",
            }}
          />
        </MwebCityImgContainer>
        <MwebCityTxtContainer>
          <MwebCityContent>{title}</MwebCityContent>
          <MwebViewLocationContainer onClick={showViewLocations}>
            <MwebViewLocationTxt>View locations</MwebViewLocationTxt>

            <MwebDownArrowIcon>
              {showViewLocation ? (
                <IoIosArrowUp
                  size={24}
                  style={{
                    position: "relative",
                    top: "-2px",
                  }}
                />
              ) : (
                <IoIosArrowDown
                  size={24}
                  style={{
                    position: "relative",
                    top: "-2px",
                  }}
                />
              )}
            </MwebDownArrowIcon>
          </MwebViewLocationContainer>
        </MwebCityTxtContainer>
      </MwebCityContainer>
      <MwebShowLocations $showViewLocation={showViewLocation}>
        {showViewLocation &&
          viewLocationOptions &&
          viewLocationOptions.map((option: any, ind: number) => {
            let lastInd = ind === viewLocationOptions.length - 1;

            return (
              <Location
                $lastInd={lastInd}
                onClick={() => handleLocationClicked(option)}
                key={option?.locationName}
              >
                <LocationImage
                  src={option.locationImageURL}
                  title={option.locationName}
                  alt={option.locationName}
                  style={{
                    borderRadius: "50%",
                    width: "56px",
                    height: "56px",
                    backgroundColor: "#ffffff",
                  }}
                />
                <LocationTxt>{option.locationName}</LocationTxt>
                <LocationDirection>
                  <FaArrowRight size={24} />
                </LocationDirection>
              </Location>
            );
          })}
      </MwebShowLocations>
    </>
  );
};
export default City;
