import styled from "styled-components";

export const BookingDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

export const MwebBookingDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: 786px) {
    display: none;
  }
`;

export const BookingDetailsHeading = styled.h2`
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 1.5rem;
  }
`;

export const InputBox = styled.input`
  padding: 0.8rem;
  border: 1px solid #cacaca;
  border-radius: 12px;
  flex: 1;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
`;

export const CouponContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const CouponErrorTxtContainer = styled.div`
  display: flex;
  gap: 0.2rem;
  color: red;
`;

export const CouponErrorTxt = styled.div`
  display: flex;
  color: red;
  font-weight: 400;
  font-size: 14px;
`;

export const FirstRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  @media only screen and (max-width: 786px) {
    flex-direction: column;
    gap: 0rem;
  }
`;

export const SecondRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  @media only screen and (max-width: 786px) {
    flex-direction: column;
    gap: 0rem;
  }
`;

export const ThirdRowContainer = styled.div`
  display: flex;
  flex-direction: column;

  // justify-content: space-between;
  @media only screen and (max-width: 786px) {
    flex-direction: column;
    gap: 0rem;
  }
`;

export const MwebFormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;

export const MwebInputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export const InputContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex: 0.7;
  gap: 0.5rem;
  flex-direction: column;
  @media only screen and (max-width: 786px) {
    flex: 1;
  }
`;

export const CouponState = styled.div`
  background-color: #34265c;
  color: #ffffff;
  border-radius: 0 12px 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0.5rem 0;
  width: 16%;
  border: none;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 18px;

  @media only screen and (max-width: 786px) {
    width: 30%;
  }
`;

export const CouponAppliedState = styled.button`
  background-color: #1c9b21;
  color: #ffffff;
  border-radius: 0 12px 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0.5rem 0;
  width: 16%;
  border: none;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 18px;

  @media only screen and (max-width: 786px) {
    width: 35%;
  }
`;

export const LabelBox = styled.label``;

export const NumberOfPeopleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
