import { useState } from "react";

import Banner from "../Home/screens/Banner/Banner";
import CopyRight from "../Home/screens/CopyRight/CopyRight";
import Footer from "../Home/screens/Footer/Footer";
import Header from "../Home/screens/Header/Header";
import Signup from "../../assets/images/Login/Signup.svg";

import { useDispatch, useSelector } from "react-redux";
import { getPhoneNumber } from "../../redux/selectors/blogsSelector";
import { useParams, useNavigate } from "react-router-dom";
import { setUpdateBookingDetails } from "../../redux/actions/blogsActions";
import { PROD } from "../../endPointsConfig";
import * as S from "./OTPVerification.style";

const OTPVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const phoneNumber = useSelector(getPhoneNumber) || "";
  let { bookingId } = useParams();
  const [otpFirstInputVal, setOtpFirstInputVal] = useState("");
  const [otpSecondInputVal, setOtpSecondInputVal] = useState("");
  const [otpThirdInputVal, setOtpThirdInputVal] = useState("");
  const [otpFourthInputVal, setOtpFourthInputVal] = useState("");
  const [error, setError] = useState(false);

  const handleOTPVerification = () => {
    const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
    fetch(`${PROD}/getUserData?phone=${bookingId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Auth: `${otpFirstInputVal}${otpSecondInputVal}${otpThirdInputVal}${otpFourthInputVal}`,
        "sessionId": sessionId
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response && response?.success) {
          dispatch(setUpdateBookingDetails(response?.resp));
          navigate(`/my_bookings`);
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        setError(true);
      });
  };

  const handleFirstInputChange = (event: any) => {
    setError(false);
    setOtpFirstInputVal(event.target.value);
    const nextfield = document.querySelector(`input[name=field-${1}]`);
    if (nextfield !== null && event.target.value !== "") {
      (nextfield as HTMLElement)?.focus();
    }
  };

  const handleSecondInputChange = (event: any) => {
    setError(false);
    setOtpSecondInputVal(event.target.value);
    const nextfield = document.querySelector(`input[name=field-${2}]`);
    if (nextfield !== null && event.target.value !== "") {
      (nextfield as HTMLElement)?.focus();
    }
  };

  const handleThirdInputChange = (event: any) => {
    setError(false);
    setOtpThirdInputVal(event.target.value);
    const nextfield = document.querySelector(`input[name=field-${3}]`);
    if (nextfield !== null && event.target.value !== "") {
      (nextfield as HTMLElement)?.focus();
    }
  };

  const handleFourthInputChange = (event: any) => {
    setError(false);
    setOtpFourthInputVal(event.target.value);
  };

  return (
    <>
    <title>The Binge Town | OTP Verification</title>
      <Header />
      <Banner />
      <S.WaitListFormContainer>
        <S.RightHalf>
          <S.Heading>OTP Verification</S.Heading>
          <S.FormContainer>
            <S.FormInstruction>
              Enter the 4 digit code sent to WhatsApp +91 {phoneNumber} and
              registered email id.
            </S.FormInstruction>
            <S.OTPVerificationContainer>
              <S.InputBox
                maxLength={1}
                required
                name="field-0"
                placeholder="X"
                onChange={(event) => handleFirstInputChange(event)}
                inputMode="numeric"
              />
              <S.InputBox
                maxLength={1}
                placeholder="X"
                name="field-1"
                required
                onChange={(event) => handleSecondInputChange(event)}
                inputMode="numeric"
              />
              <S.InputBox
                maxLength={1}
                required
                placeholder="X"
                name="field-2"
                onChange={(event) => handleThirdInputChange(event)}
                inputMode="numeric"
              />
              <S.InputBox
                maxLength={1}
                required
                placeholder="X"
                name="field-3"
                onChange={(event) => handleFourthInputChange(event)}
                inputMode="numeric"
              />
            </S.OTPVerificationContainer>
            {error ? (
              <S.ErrorContainer style={{ color: "red" }}>
                Wrong OTP, please try again
              </S.ErrorContainer>
            ) : null}
            <S.BookNowBtn onClick={handleOTPVerification}>Submit</S.BookNowBtn>
          </S.FormContainer>
        </S.RightHalf>
        <S.LeftHalf>
          <img
            src={Signup}
            alt="login"
            title="Manage My Bookings | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              overflow: "hidden",
            }}
          />
        </S.LeftHalf>
      </S.WaitListFormContainer>
      <Footer />
      <CopyRight />
    </>
  );
};
export default OTPVerification;
